import React from "react";
import './ArchiveServicesBanner.css';
function ArchiveServicesBanner(props) {
  return (
<section className="section section-archive-services-banner">
<div className="container">
<div className="section-archive-services-banner__inner">
  <h1 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.bannerInfo.main_services_headline}</h1>
  <h3 className="section-under-headline">{props.bannerInfo.main_services_description}</h3>
  </div>
</div>
</section>
);
}
export default ArchiveServicesBanner;
