import React from "react";
import sprite from '../media/icons.svg';
import './ServicesTypeBanner.css';
function ServicesTypeBanner(props) {
  return (
<section className="section section-type-banner">
<div className="container">
<h1 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.typeBannerTitle}</h1>
<h3 className="section-under-headline">{props.typeBannerDescription}</h3>
<div className="section-type-banner__abstraction abstraction">
<svg className="abstraction-img-long">
<use href={sprite + "#abstraction-long"}></use>
</svg>
</div>
</div>
</section>
);
}
export default ServicesTypeBanner;
