import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './Collebrate.css';
function Collebrate() {
  const location = useLocation();
  const [valueName, setValueName] = useState('');
  const [valueContact, setValueContact] = useState('');
  const [infoCollebrate, setInfoCollebrate] = useState([]);
  const [language] = useState(getLanguage(location));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  function handlerValueName(event){
    setValueName(event.target.value);
  }
  function handlerValueContact(event){
    setValueContact(event.target.value);
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function handlerSubmitContact(event){
    event.preventDefault();
    fetch("https://ukrservisproekt.com.ua/wp-content/themes/urkserviceprojecttheme/form-submit-contacts-withoutmessage.php",
      {   method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode : 'same-origin',
          credentials: 'same-origin' ,
          body: JSON.stringify({name: valueName, contact: valueContact})
      })
      .then(function(response) {
        return response.text()
      }).then(function(text) {
        let result = JSON.parse(text);
        if(result === 'success'){
          setSuccess(true);
          setLoading(false);
        } else {
          setErrors(result);
          setLoading(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      });
  }
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-collebrate-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setInfoCollebrate(result);
            },
            (error) => {

            }
          )
  }, [language]);
  return (
    <>
      {infoCollebrate.acf ? <section className="section section-collebrate">
        <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{infoCollebrate.acf.section_collebrate.headline}</h2>
        <p className="section-description section-description_theme_light">{infoCollebrate.acf.section_collebrate.description}</p>
        <div className="section-collebrate__btn">
        <Popup trigger={<button className="submit-button button" type="button">
                    <span className="submit-button__txt">{infoCollebrate.acf.section_collebrate.button_text}</span>
                    <span className="submit-button__arrow-wrapper">
                      <svg className="submit-button__arrow">
                        <use href={sprite + "#arrow"}></use>
                      </svg>
                    </span>
                  </button>} modal>
                  {success ? <div className="section-collebrate__form-wrapper">
                    <div className="section-collebrate__success-headline">{translateWords({ua: 'Дякуємо за повідомлення!', ru: 'Спасибо за сообщение!', en: 'Thank you for message!'}, location)}</div>
                    <div className="section-collebrate__success-message">{translateWords({ua: 'Наш менеджер зв\'яжеться з Вами найближчим часом.', ru: 'Наш менеджер свяжется с Вами в ближайшее время.', en: 'Our manager will contact you shortly.'}, location)}</div>
                  </div> : <div className="section-collebrate__form-wrapper">
                    <h3 className="section-collebrate__headline section-collebrate__headline_theme_light">{translateWords({ua: 'Напишіть нам', ru: 'Напишите нам', en: 'Write to us'}, location)}</h3>
                    <form className="form" onSubmit={handlerSubmitContact}>
                      <div className="input-container">
                        <input type="text" id="name" value={valueName} onChange={handlerValueName} disabled={loading ? 'disabled' : ''} />
                        <label htmlFor="name">{translateWords({ua: 'Ваше ім\'я', ru: 'Ваше имя', en: 'Your name'}, location)}</label>
                        <div className="bar"></div>
                      </div>
                      <div className="input-container">
                        <input type="text" id="contact" value={valueContact} onChange={handlerValueContact} disabled={loading ? 'disabled' : ''} />
                        <label htmlFor="contact">{translateWords({ua: 'Пошта або телефон', ru: 'Почта или телефон', en: 'Mail or phone'}, location)}</label>
                        <div className="bar"></div>
                      </div>
                      {loading ? <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                         <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                      </svg> : ''}
                      {errors && <div className="errors">
                        <div className="errors__content">
                        {Object.values(errors).map((item, index) => {
                          return <p className="error" key={index}>{item}</p>;
                        })}
                        </div>
                      </div>}
                      <button className={loading ? 'submit-button submit-button__loading' : 'submit-button'} type="submit" name="submit" disabled={loading ? 'disabled' : ''}>
                        <span className="submit-button__txt">{translateWords({ua: 'Зв\'язатись', ru: 'Связаться', en: 'Get in touch'}, location)}</span>
                        <span className="submit-button__arrow-wrapper">
                          <svg className="submit-button__arrow">
                            <use href={sprite + "#arrow"}></use>
                          </svg>
                        </span>
                      </button>
                    </form>
                  </div>}
        </Popup>
        </div>
    </section> : ''}
    </>
  );
}
export default Collebrate;
