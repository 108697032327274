import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './ArchiveNewsList.css';
function ArchiveNewsList(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
      <section className="section section-news-list">
      <div className="container">
        <div className="news-wgts">
          {Object.values(props.newsItems).map((newsItem, index) => {
            let url;
            if(location.pathname.indexOf('/ru') > -1){
              url = `/ru/notes/${newsItem.slug}`;
            } else if(location.pathname.indexOf('/en') > -1){
              url = `/en/notes/${newsItem.slug}`;
            } else {
              url = `/notes/${newsItem.slug}`;
            }
            return <div className="news-wgt" key={index} data-img={newsItem.images.full}>
              <div className="news-wgt__content">
                <Link className="news-wgt__link" to={url} params={{slug: newsItem.slug}} onClick={event => {handlerComponents(event, url)}}>
                  <h3 className="news-wgt__headline">{newsItem.title.rendered}</h3>
                </Link>
                <div className="news-wgt__description" dangerouslySetInnerHTML={{
                  __html: newsItem.excerpt.rendered
                }}></div>
              </div>
              <div className="news-wgt__footer">
                <div className="news-wgt__footer-left">
                  <Link className="btn btn-main" to={url} params={{slug: newsItem.slug}} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
                </div>
                <div className="news-wgt__footer-right">
                  <Link to={url} params={{slug: newsItem.slug}} onClick={event => {handlerComponents(event, url)}}>
                    <img className="img-block" src={newsItem.images['ukrserviceprojecttheme-notes-thumbnail']} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          })}
        </div>
        </div>
      </section>
  );
}
export default ArchiveNewsList;
