import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import HomeBanner from './components/HomeBanner';
import HomeAbout from './components/HomeAbout';
import HomeServices from './components/HomeServices';
import HomeProjects from './components/HomeProjects';
import HomeStatistics from './components/HomeStatistics';
import HomeNews from './components/HomeNews';
import HomeTestimonials from './components/HomeTestimonials';
import HomeClients from './components/HomeClients';
import HomeContacts from './components/HomeContacts';
function Home() {
  const location = useLocation();
  const [itemsBanner, setBannerItems] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsService, setServiceItems] = useState([]);
  const [itemsProject, setProjectItems] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [itemsNews, setNewsItems] = useState([]);
  const [itemsTestimonials, setTestimonialsItems] = useState([]);
  const [itemsClients, setClientsItems] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = translateWords({ua: 'Укрсервіспроєкт | Проектуємо майбутнє', ru: 'Укрсервиспроект | Проектируем будущее', en: 'Ukrservisproekt | We are designing the future'}, location);
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/acf/v3/options/acf-main-slider-theme`)
      .then(res => res.json())
      .then(
        (result) => {
          setBannerItems(result);
          fetch(`${language}/wp-json/acf/v3/options/acf-main-page-theme`)
            .then(res => res.json())
            .then(
              (result) => {
                setItems(result);
                fetch(`${language}/wp-json/acf/v3/options/acf-statistics-theme`)
                  .then(res => res.json())
                  .then(
                    (result) => {
                      setStatistics(result);
                      fetch(`${language}/wp-json/wp/v2/services?per_page=10`)
                        .then(res => res.json())
                        .then(
                          (result) => {
                            setServiceItems(result);
                            fetch(`${language}/wp-json/wp/v2/projects?per_page=5`)
                              .then(res => res.json())
                              .then(
                                (result) => {
                                  setProjectItems(result);
                                  fetch(`${language}/wp-json/wp/v2/notes?per_page=3`)
                                    .then(res => res.json())
                                    .then(
                                      (result) => {
                                        setNewsItems(result);
                                        fetch(`${language}/wp-json/wp/v2/testimonials?per_page=10`)
                                          .then(res => res.json())
                                          .then(
                                            (result) => {
                                              setTestimonialsItems(result);
                                              fetch(`${language}/wp-json/wp/v2/clients?per_page=10`)
                                                .then(res => res.json())
                                                .then(
                                                  (result) => {
                                                    setClientsItems(result);
                                                    fetch(`${language}/wp-json/acf/v3/options/acf-contacts-theme`)
                                                      .then(res => res.json())
                                                      .then(
                                                        (result) => {
                                                          setContacts(result);
                                                        },
                                                        (error) => {

                                                        }
                                                      )
                                                  },
                                                  (error) => {

                                                  }
                                                )
                                            },
                                            (error) => {

                                            }
                                          )
                                      },
                                      (error) => {

                                      }
                                    )
                                },
                                (error) => {

                                }
                              )
                          },
                          (error) => {

                          }
                        )
                    },
                    (error) => {

                    }
                  )
              },
              (error) => {

              }
            )
        },
        (error) => {

        }
      )
  }, [language]);

  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(itemsBanner.acf && itemsBanner.acf.front_banner_slider && items.acf && itemsService && itemsProject && statistics.acf && itemsNews && itemsTestimonials && itemsClients && contacts.acf){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  let loaderItems;
  if(location.pathname.indexOf('/ru') > -1) {
    loaderItems = <div className="preloader-box">
      <div>У</div>
      <div>К</div>
      <div>Р</div>
      <div>С</div>
      <div>Е</div>
      <div>Р</div>
      <div>В</div>
      <div>И</div>
      <div>С</div>
    </div>
  } else if(location.pathname.indexOf('/en') > -1) {
    loaderItems = <div className="preloader-box">
      <div>U</div>
      <div>K</div>
      <div>R</div>
      <div>S</div>
      <div>E</div>
      <div>R</div>
      <div>V</div>
      <div>I</div>
      <div>S</div>
    </div>
  } else {
    loaderItems = <div className="preloader-box">
      <div>У</div>
      <div>К</div>
      <div>Р</div>
      <div>С</div>
      <div>Е</div>
      <div>Р</div>
      <div>В</div>
      <div>І</div>
      <div>С</div>
    </div>
  }
  return (
    <>
    {(itemsBanner.acf && itemsBanner.acf.front_banner_slider) && (items.acf && items.acf.about_section) && (itemsService && items.acf) && (itemsProject && items.acf) && (statistics.acf && items.acf) && (itemsNews && items.acf) && (itemsTestimonials && items.acf) && (itemsClients && items.acf) && (contacts.acf && items.acf) ?
      <>
      {itemsBanner.acf && itemsBanner.acf.front_banner_slider ? <HomeBanner itemsBanner={itemsBanner} contacts={contacts} /> : ''}
      {items.acf && items.acf.about_section ? <HomeAbout aboutInfo={items.acf.about_section} /> : ''}
      {itemsService && items.acf ? <HomeServices servicesItem={itemsService} servicesInfo={items.acf.services_section} /> : ''}
      {itemsProject && items.acf ? <HomeProjects projectsItem={itemsProject} projectsInfo={items.acf.projects_section} /> : ''}
      {statistics.acf && items.acf ? <HomeStatistics staticticsItem={statistics.acf} statisticsInfo={items.acf.statistics_section} /> : ''}
      {itemsNews && items.acf ? <HomeNews newsItem={itemsNews} newsHeadline={items.acf.notes_section_headline} /> : ''}
      {itemsTestimonials && items.acf ? <HomeTestimonials testimonialsItem={itemsTestimonials} testimonialsInfo={items.acf.testimonials_section} /> : ''}
      {itemsClients && items.acf ? <HomeClients clientsItem={itemsClients} clientsInfo={items.acf.clients_section} /> : ''}
      {contacts.acf && items.acf ? <HomeContacts contacts={contacts.acf} contactsHeadline={items.acf.contacts_section_headline} /> : ''}
      </> : <div className="preloader">{loaderItems}</div>}
    </>
  );
}
export default Home;
