import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TermsBanner from './components/TermsBanner';
import TermsContent from './components/TermsContent';
import Collebrate from './components/Collebrate';
function Terms() {
  const location = useLocation();
  const [termsInfo, setTermsInfo] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Terms page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/pages?slug=terms`)
      .then(res => res.json())
      .then(
        (result) => {
          setTermsInfo(result);
        },
        (error) => {

        }
      )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(termsInfo[0]){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {termsInfo[0] ?
        <>
        <TermsBanner termsTitle={termsInfo[0].title} />
        <TermsContent termsContent={termsInfo[0].content} />
        </>
        : ''}
      <Collebrate />
    </>
  );
}
export default Terms;
