import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import ServicesTypeBanner from './components/ServicesTypeBanner';
import ServicesTypeChoose from './components/ServicesTypeChoose';
import Collebrate from './components/Collebrate';
function ServicesType() {
  const location = useLocation();
  const [infoChooseTypeServices, setInfoChooseTypeServices] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Service type page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-type-services-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setInfoChooseTypeServices(result);
            },
            (error) => {

            }
          )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(infoChooseTypeServices.acf){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {infoChooseTypeServices.acf ? <ServicesTypeBanner typeBannerTitle={infoChooseTypeServices.acf.type_services_headline} typeBannerDescription={infoChooseTypeServices.acf.type_services_description} /> : ''}
      {infoChooseTypeServices.acf && infoChooseTypeServices.acf.type_services_list ? <ServicesTypeChoose typeChooseList={infoChooseTypeServices.acf.type_services_list} /> : ''}
      <Collebrate />
    </>
  );
}
export default ServicesType;
