import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import ContentNav from './ContentNav';
import './ArchiveServicesList.css';
function ArchiveServicesList(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
<section className="section section-services-list">
<div className="container">
<div className="section_row">
<div className="section__col section-services-list__left">
{Object.values(props.serviceItems).map((item, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/services/${item.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/services/${item.slug}`;
  } else {
    url = `/services/${item.slug}`;
  }
  let classWgt = (index % 2 === 0) ? 'service-wgt' : 'service-wgt service-wgt__reverse';
return <div id={`item-${index}`} className={classWgt} key={index}>
<div className="service-wgt__img-wrap">
  <Link to={url} onClick={event => {handlerComponents(event, url)}}>
    <img className="img-block" src={item.images['service-front-thumbnail']} alt="" />
  </Link>
</div>
<div className="service-wgt__content">
  <h2 className="service-wgt__headline">{item.title.rendered}</h2>
  <p className="service-wgt__description" dangerouslySetInnerHTML={{
    __html: item.excerpt.rendered
  }}></p>
  <Link className="btn btn-main" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
</div>
</div>
})}
</div>
<div className="section__col section-services-list__right">
<ContentNav items={props.serviceItems} />
</div>
</div>
</div>
</section>
);
}
export default ArchiveServicesList;
