import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleServiceDiscuss.css';
function SingleServiceDiscuss(props) {
  const location = useLocation();
  const [valueName, setValueName] = useState('');
  const [valueContact, setValueContact] = useState('');
  const [valueMessage, setValueMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSubmitContact(event){
    event.preventDefault();
    fetch("https://ukrservisproekt.com.ua/wp-content/themes/urkserviceprojecttheme/form-submit-contacts.php",
      {   method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode : 'same-origin',
          credentials: 'same-origin' ,
          body: JSON.stringify({name: valueName, contact: valueContact, message: valueMessage, service: props.serviceName.rendered})
      })
      .then(function(response) {
        return response.text()
      }).then(function(text) {
        let result = JSON.parse(text);
        if(result === 'success'){
          setSuccess(true);
          setLoading(false);
        } else {
          setErrors(result);
          setLoading(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      });
  }
  function handlerValueName(event){
    setValueName(event.target.value);
  }
  function handlerValueContact(event){
    setValueContact(event.target.value);
  }
  function handlerValueMessage(event){
    setValueMessage(event.target.value);
  }
  return (
    <>
      <section className="section section-service-discuss">
      <div className="container">
      <div className="section-service-discuss__inner">
        <div className="section-service-discuss__abstraction abstraction">
          <svg className="abstraction-img-long">
            <use href={sprite + "#abstraction-long"}></use>
          </svg>
        </div>
        <div className="section-service-discuss__info">
          <h2 className="section-service-discuss__headline">{translateWords({ua: 'Бажаєте обговорити проєкт?', ru: 'Хотите обсудить проект?', en: 'Want to discuss a project?'}, location)}</h2>
          <p>{translateWords({ua: 'Якщо Ви бажаєте обговорити свій проєкт, зв\'яжіться з нами и ми надамо Вам бажану інформацію.', ru: 'Если Вы хотите обсудить свой проект, свяжитесь с нами и предоставим Вам желаемую информацию.', en: 'If you want to discuss your project, contact us and we will provide you with the desired information.'}, location)}</p>
        </div>
        <div className="section-service-discuss__form">
        {success ?
        <><div className="checkmark checkmark__discuss draw">
          <div className="checkmark__content">
            <div className="checkmark__headline">{translateWords({ua: 'Дякуємо за повідомлення!', ru: 'Спасибо за сообщение!', en: 'Thank you for message!'}, location)}</div>
            <div className="checkmark__message">{translateWords({ua: 'Наш менеджер зв\'яжеться з Вами найближчим часом.', ru: 'Наш менеджер свяжется с Вами в ближайшее время.', en: 'Our manager will contact you shortly.'}, location)}</div>
          </div>
          </div></> :
          <div className="section-service-discuss__form-wrapper">
            <form className="form" onSubmit={handlerSubmitContact}>
              <div className="input-container">
                <input type="text" id="name" value={valueName} onChange={handlerValueName} disabled={loading ? 'disabled' : ''}  />
                <label htmlFor="name">{translateWords({ua: 'Ваше ім\'я', ru: 'Ваше имя', en: 'Your name'}, location)}</label>
                <div className="bar"></div>
              </div>
              <div className="input-container">
                <input type="text" id="contact" value={valueContact} onChange={handlerValueContact} disabled={loading ? 'disabled' : ''} />
                <label htmlFor="contact">{translateWords({ua: 'Пошта або телефон', ru: 'Почта или телефон', en: 'Mail or phone'}, location)}</label>
                <div className="bar"></div>
              </div>
              <div className="input-container input-container_size_lg">
                <input type="text" id="message" name="message" value={valueMessage} onChange={handlerValueMessage} disabled={loading ? 'disabled' : ''} />
                <label htmlFor="message">{translateWords({ua: 'Текст повідомлення', ru: 'Текст сообщения', en: 'Message text'}, location)}</label>
                <div className="bar"></div>
              </div>
              {loading ? <svg className="spinner spinner__discuss" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                 <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
              </svg> : ''}
              {errors && <div className="errors">
                <div className="errors__content">
                {Object.values(errors).map((item, index) => {
                  return <p className="error" key={index}>{item}</p>;
                })}
                </div>
              </div>}
              <div className="form-submit__wrap">
                <button className={loading ? 'submit-button submit-button__discuss submit-button__loading' : 'submit-button submit-button__discuss'} type="submit" name="submit">{translateWords({ua: 'Зв\'язатись', ru: 'Связаться', en: 'Get in touch'}, location)}</button>
              </div>
            </form>
          </div>}
        </div>
        </div>
        </div>
      </section>
    </>
  );
}
export default SingleServiceDiscuss;
