import React from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './NotFoundContent.css';
function NotFoundContent() {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  return (
    <section className="section not-found">
      <div className="not-found__abstraction not-found__abstraction_top abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      <div className="not-found__value">404</div>
      <div className="not-found__headline">{translateWords({ua: 'Сторінку не знайдено', ru: 'Страница не найдена', en: 'Page Not Found'}, location)}</div>
      <p className="not-found__description">{translateWords({ua: 'На жаль, ми не можемо знайти сторінку, яку ви шукаєте. Натисніть нижче, щоб перейти на домашню сторінку.', ru: 'К сожалению, мы не можем найти страницу, которую вы ищете. Щелкните ниже, чтобы перейти на домашнюю страницу.', en: 'Sorry, we cannot find the page you are looking for. Click below to go to the homepage.'}, location)}</p>
      <a className="btn btn-main btn-main_theme_dark" href="https://google.com">{translateWords({ua: 'Перейти на домашню сторінку', ru: 'На главную', en: 'Go Home Page'}, location)}</a>
      <div className="not-found__abstraction not-found__abstraction_bottom abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
    </section>
  );
}
export default NotFoundContent;
