import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './HomeNews.css';
function HomeNews(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
      history.push(path);
    }, 1000);
  }
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = '/ru/';
  } else if(location.pathname.indexOf('/en') > -1){
    url = '/en/';
  } else {
    url = '/';
  }
  return (
    <>
  <section className="section section-front-news">
    <div className="container">
      <div className="section-front-news__abstraction abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      <h2 className="section-headline section-headline_size_sm">{props.newsHeadline}</h2>
      <div className="news-wgts">
        {Object.values(props.newsItem).map((item, index) => {
          return <div className="news-wgt" key={index}>
                  <div className="news-wgt__content">
                    <Link className="news-wgt__link" to={`/notes/${item.slug}`} onClick={event => {handlerComponents(event, `/notes/${item.slug}`)}}>
                      <h3 className="news-wgt__headline">{item.title.rendered}</h3>
                    </Link>
                    <p className="news-wgt__description" dangerouslySetInnerHTML={{
                      __html: item.excerpt.rendered
                    }}></p>
                  </div>
                  <div className="news-wgt__footer">
                    <div className="news-wgt__footer-left">
                      <Link className="btn btn-main" to={`/notes/${item.slug}`} onClick={event => {handlerComponents(event, `/notes/${item.slug}`)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
                    </div>
                    <div className="news-wgt__footer-right">
                      <Link to={`/notes/${item.slug}`} onClick={event => {handlerComponents(event, `/notes/${item.slug}`)}}>
                        <img className="img-block" src={item.images['ukrserviceprojecttheme-notes-thumbnail']} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
        })}
      </div>
      <div className="section-front-news__footer">
        <Link className="btn btn-main" to={`${url}notes`} onClick={event => {handlerComponents(event, `${url}notes`)}}>{translateWords({ua: 'Переглянути всі записи', ru: 'Просмотреть все записи', en: 'View all entries'}, location)}</Link>
      </div>
      </div>
    </section>
    </>
  );
}
export default HomeNews;
