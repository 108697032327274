import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './SingleNewsInteresting.css';
function SingleNewsInteresting(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
  <section className="section section_theme_dark single-news-interesting">
  <div className="container">
    <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{translateWords({ua: 'Може бути цікаво', ru: 'Может быть интересно', en: 'It may be interesting'}, location)}</h2>
    <div className="news-wgts">
      {Object.values(props.interestingItems).map((itemNews, index) => {
        let url;
        if(location.pathname.indexOf('/ru') > -1){
          url = `/ru/notes/${itemNews.slug}`;
        } else if(location.pathname.indexOf('/en') > -1){
          url = `/en/notes/${itemNews.slug}`;
        } else {
          url = `/notes/${itemNews.slug}`;
        }
      return <div className="news-wgt news-wgt_theme_light" key={index}>
        <div className="news-wgt__content">
          <Link className="news-wgt__link" to={url} onClick={event => {handlerComponents(event, url)}}>
            <h3 className="news-wgt__headline">{itemNews.title.rendered}</h3>
          </Link>
          <p className="news-wgt__description" dangerouslySetInnerHTML={{
            __html: itemNews.excerpt.rendered
          }}></p>
        </div>
        <div className="news-wgt__footer">
          <div className="news-wgt__footer-left">
            <Link className="btn btn-main btn-main_theme_light" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
          </div>
          <div className="news-wgt__footer-right">
            <Link to={url} onClick={event => {handlerComponents(event, url)}}>
              <img className="img-block" src={itemNews.images['ukrserviceprojecttheme-notes-thumbnail']} alt="" />
            </Link>
          </div>
        </div>
      </div>
      })}
    </div>
    </div>
    </section>
);
}
export default SingleNewsInteresting;
