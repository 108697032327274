import React from "react";
import './SingleProjectContent.css';
function SingleProjectContent(props) {
  return (
    <section id="projects-content" className="section single-content" dangerouslySetInnerHTML={{
      __html: props.items.content.rendered
    }}>
    </section>
  );
}
export default SingleProjectContent;
