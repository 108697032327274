import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation
} from "react-router-dom";
import SingleServiceDocBanner from './components/SingleServiceDocBanner';
import SingleServiceContent from './components/SingleServiceContent';
import SingleServiceDiscuss from './components/SingleServiceDiscuss';
import SingleServiceCases from './components/SingleServiceCases';
import Collebrate from './components/Collebrate';
function SingleServiceDoc() {
  const location = useLocation();
  let { slug } = useParams();
  const [items, setItems] = useState([]);
  const [cases, setCases] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Service Documents single page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/services-doc?slug=${slug}`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
        (error) => {

        }
      )
  }, [language, slug]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(items[0]){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  useEffect(() => {
    fetch(`${language}/wp-json/ukr/v2/my_project_cases_query/?service[slug]=${slug}`)
      .then(res => res.json())
      .then(
        (result) => {
          setCases(result);
        },
        (error) => {

        }
      )
  }, [language, slug]);
  return (
    <>
      {items[0] ? <>
        <SingleServiceDocBanner serviceTitle={items[0].title} serviceExcerpt={items[0].excerpt} />
        <SingleServiceContent serviceContent={items[0].content} />
        <SingleServiceDiscuss serviceName={items[0].title} />
    </> : ''}
      {cases ? <SingleServiceCases cases={cases} /> : ''}
      <Collebrate />
    </>
  );
}
export default SingleServiceDoc;
