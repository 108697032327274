import React from "react";
import sprite from '../media/icons.svg';
import './AboutBanner.css';
function AboutBanner(props) {
  return (
  <section className="section section-about-info">
    <div className="container">
      <h2 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.bannerTitle.rendered}</h2>
      <div className="section_row">
        <div className="section__col section-about-info__left">
          <h3 className="section-under-headline">{props.bannerUntitle}</h3>
          <div className="section-front-about__content" dangerouslySetInnerHTML={{
            __html: props.bannerContent.rendered
          }}>
          </div>
        </div>
        <div className="section__col section-about-info__right">
          <div className="section-about-info__right_abstraction abstraction">
            <svg className="abstraction-img">
              <use href={sprite + "#abstraction"}></use>
            </svg>
          </div>
          <img className="section-about-info__img img-block" src={props.bannerImg} alt="" />
        </div>
      </div>
      </div>
    </section>
  );
}
export default AboutBanner;
