import React, { useState, useEffect } from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './ArchiveProjectsBanner.css';
function ArchiveProjectsBanner(props) {
  const location = useLocation();
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/projects-category`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  return (
    <section className="section section-projects-banner">
      <div className="container">
        <div className="section_row">
          <div className="section__col section-projects-banner__left">
            <h1 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.categoryPage ? props.categoryInfo.name : props.projectsPageInfo.projects_headline}</h1>
            <h3 className="section-under-headline">{props.categoryPage ? props.categoryInfo.description : props.projectsPageInfo.projects_description}</h3>
          </div>
          <div className="section__col section-projects-banner__right">
            <ul className="section-projects-banner__nav content-nav">
            {Object.values(items).map((item, index) => {
              let classLink = '';
              if(`/category-projects/${item.slug}` === location.pathname || `/category-projects/${item.slug}/` === location.pathname){
                classLink = 'content-nav__link active';
              } else {
                classLink = 'content-nav__link';
              }
              let url;
              if(location.pathname.indexOf('/ru') > -1){
                url = `/ru/category-projects/${item.slug}`;
              } else if(location.pathname.indexOf('/en') > -1){
                url = `/en/category-projects/${item.slug}`;
              } else {
                url = `/category-projects/${item.slug}`;
              }
            return <li className="content-nav__item" key={index}>
              <Link className={classLink} to={url} onClick={event => {handlerComponents(event, url)}}>{item.name}</Link >
            </li>
            })}
            </ul>
          </div>
        </div>
      </div>
    </section>
);
}
export default ArchiveProjectsBanner;
