import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './ContactsInfo.css';
function ContactsInfo(props) {
  const location = useLocation();
  const [valueName, setValueName] = useState('');
  const [valueContact, setValueContact] = useState('');
  const [valueMessage, setValueMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSubmitContact(event){
    event.preventDefault();
    fetch("https://ukrservisproekt.com.ua/wp-content/themes/urkserviceprojecttheme/form-submit-contacts.php",
      {   method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode : 'same-origin',
          credentials: 'same-origin' ,
          body: JSON.stringify({name: valueName, contact: valueContact, message: valueMessage})
      })
      .then(function(response) {
        return response.text()
      }).then(function(text) {
        let result = JSON.parse(text);
        if(result === 'success'){
          setSuccess(true);
          setLoading(false);
        } else {
          setErrors(result);
          setLoading(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      });
  }
  function handlerValueName(event){
    setValueName(event.target.value);
  }
  function handlerValueContact(event){
    setValueContact(event.target.value);
  }
  function handlerValueMessage(event){
    setValueMessage(event.target.value);
  }

  return (
    <section className="section section-contacts">
    <div className="container">
<div className="section_row">
<div className="section__col contacts-wgt">
{props.contacts ? <>
  <div className="contact-info-page">
    <svg className="contact-info-page__icon-phone">
      <use href={sprite + "#phone"}></use>
    </svg>
    <div className="contact-info-page__content">
      <h2 className="contact-info-page__headline">{props.contacts.phones.length > 1 ? translateWords({ua: 'Телефони', ru: 'Телефоны', en: 'Phones'}, location) : translateWords({ua: 'Телефон', ru: 'Телефон', en: 'Phones'}, location)}</h2>
      {Object.values(props.contacts.phones).map((phone, index) => {
        let obj = Object.assign({}, phone);
        return Object.values(obj).map((item, i) => {
          let phoneCall = item.replace(/\D+/g,"");
          return <a className="contact-info-page__value" href={"tel:" + phoneCall} key={i}>{item}</a>
        })
      })}
    </div>
  </div>
  <div className="contact-info-page">
    <svg className="contact-info-page__icon-mail">
      <use href={sprite + "#mail"}></use>
    </svg>
    <div className="contact-info-page__content">
      <h2 className="contact-info-page__headline">{props.contacts.emails.length > 1 ? translateWords({ua: 'Електроні пошти', ru: 'Электронные почты', en: 'Emails'}, location) : translateWords({ua: 'Електрона пошта', ru: 'Электронная почта', en: 'Email'}, location)}</h2>
      {Object.values(props.contacts.emails).map((email, index) => {
        let obj = Object.assign({}, email);
        return Object.values(obj).map((item, i) => {
          return <a className="contact-info-page__value" href={"mailto:" + item} key={i}>{item}</a>
        })
      })}
    </div>
  </div>
  <div className="contact-info-page">
    <svg className="contact-info-page__icon-geo">
      <use href={sprite + "#map"}></use>
    </svg>
    <div className="contact-info-page__content">
      <h2 className="contact-info-page__headline">Адреса</h2>
      <span className="contact-info-page__value">{props.contacts.address}</span>
    </div>
  </div>
  <ul className="social-info-page">
    <li className="social-info-page__item">
      <a className="social-info-page__link" href={props.contacts.facebook_link} target="_blank" rel="noreferrer">
        <svg className="social-info-page__icon">
          <use href={sprite + "#facebook"}></use>
        </svg>
      </a>
    </li>
    <li className="social-info-page__item">
      <a className="social-info-page__link" href={props.contacts.youtube_link} target="_blank" rel="noreferrer">
        <svg className="social-info-page__icon">
          <use href={sprite + "#youtube"}></use>
        </svg>
      </a>
    </li>
    <li className="social-info-page__item">
      <a className="social-info-page__link" href={props.contacts.telegram_link} target="_blank" rel="noreferrer">
        <svg className="social-info-page__icon">
          <use href={sprite + "#telegram"}></use>
        </svg>
      </a>
    </li>
  </ul>
  </> : ''}
</div>
<div className="section__col contacts-wgt contacts-wgt__form">
  {success ?
  <><div className="checkmark draw">
    <div className="checkmark__content">
      <div className="checkmark__headline">{translateWords({ua: 'Дякуємо за повідомлення!', ru: 'Спасибо за сообщение!', en: 'Thank you for message!'}, location)}</div>
      <div className="checkmark__message">{translateWords({ua: 'Наш менеджер зв\'яжеться з Вами найближчим часом.', ru: 'Наш менеджер свяжется с Вами в ближайшее время.', en: 'Our manager will contact you shortly.'}, location)}</div>
    </div>
    </div></> : <div className="contacts-wgt__form-wrapper">
    {!success && <h3 className="contacts-wgt__headline contacts-wgt__headline_lg contacts-wgt__headline_theme_light">{translateWords({ua: 'Напишіть нам', ru: 'Напишите нам', en: 'Write to us'}, location)}</h3>}
    <form className="form" onSubmit={handlerSubmitContact}>
      <div className="input-container">
        <input type="text" id="name" name="name" value={valueName} onChange={handlerValueName} disabled={loading ? 'disabled' : ''} />
        <label htmlFor="name">{translateWords({ua: 'Ваше ім\'я', ru: 'Ваше имя', en: 'Your name'}, location)}</label>
        <div className="bar"></div>
      </div>
      <div className="input-container">
        <input type="text" id="contact" name="contact" value={valueContact} onChange={handlerValueContact} disabled={loading ? 'disabled' : ''} />
        <label htmlFor="contact">{translateWords({ua: 'Пошта або телефон', ru: 'Почта или телефон', en: 'Mail or phone'}, location)}</label>
        <div className="bar"></div>
      </div>
      <div className="input-container input-container_size_lg">
        <input type="text" id="message" name="message" value={valueMessage} onChange={handlerValueMessage} disabled={loading ? 'disabled' : ''} />
        <label htmlFor="message">{translateWords({ua: 'Текст повідомлення', ru: 'Текст сообщения', en: 'Message text'}, location)}</label>
        <div className="bar"></div>
      </div>
      {loading ? <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
         <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg> : ''}
      {errors && <div className="errors">
        <div className="errors__content">
        {Object.values(errors).map((item, index) => {
          return <p className="error" key={index}>{item}</p>;
        })}
        </div>
      </div>}
      <button className={loading ? 'submit-button submit-button__loading' : 'submit-button'} type="submit" name="submit" disabled={loading ? 'disabled' : ''} >
        <span className="submit-button__txt">{translateWords({ua: 'Зв\'язатись', ru: 'Связаться', en: 'Get in touch'}, location)}</span>
        <span className="submit-button__arrow-wrapper">
          <svg className="submit-button__arrow">
            <use href={sprite + "#arrow"}></use>
          </svg>
        </span>
      </button>
    </form>
  </div>}
</div>
<div className="section__col contacts-wgt">
{props.works ? <>
  <div className="contacts-wgt__header">
      <svg className="contacts-wgt__header-clock-icon">
        <use href={sprite + "#clock"}></use>
      </svg>
    <div className="contacts-wgt__header-inner">
      <h3 className="contacts-wgt__header-headline">{translateWords({ua: 'Часи праці', ru: 'Время работы', en: 'Working hours'}, location)}</h3>
      <div className="contacts-wgt__header-status-info">{translateWords({ua: 'Відчинено', ru: 'Открыто', en: 'Opened'}, location) + ': ' + translateWords({ua: 'Закриється в', ru: 'Закроется в', en: 'Closes at'}, location)} 18:00</div>
    </div>
  </div>
  <div className="hours-work hours-work__page">
    {Object.values(props.works.working_hours).map((item, index) => {
    return <div className="hours-work__wgt" key={index}>
      <div className="hours-work__key">{item.day}</div>
      <div className="hours-work__value">{item.value}</div>
    </div>
    })}
  </div> </> : ''}
</div>
</div>
</div>
</section>
  );
}
export default ContactsInfo;
