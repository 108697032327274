import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './ServicesTypeChoose.css';
function ServicesTypeChoose(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
<section className="section section-type-choose">
<div className="container">
<div className="section-type-choose__inner">
{Object.values(props.typeChooseList).map((item, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/${item.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/${item.slug}`;
  } else {
    url = `${item.slug}`;
  }
  return <div className="service-type-wgt" key={index}>
  <div className="overlay"></div>
  <img className="img-block" src={item.image.sizes.large} alt="" />
  <div className="service-type-wgt__content">
  <h4 className="service-type-wgt__headline">{item.headline}</h4>
  <p className="service-type-wgt__description">{item.description}</p>
  <div className="service-type-wgt__btn">
    <Link className="btn btn-main btn-main_theme_light" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
  </div>
  </div>
  </div>
})}
</div>
</div>
</section>
);
}
export default ServicesTypeChoose;
