import React, { useState } from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import './HomeServices.css';
function HomeServices(props) {
  SwiperCore.use([Autoplay, Navigation, Pagination])
  const [currentItem, setCurrentItem] = useState('01');
  const [totalNum, setTotalNum] = useState(null);
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSlideChange(e){
    let current = e.realIndex + 1;
    let currentItem = (current < 10) ? '0' + current : current;
    setCurrentItem(currentItem);
  }
  function handlerOnSwiper(e){
    let total = 5;
    let totalItem = (total < 10) ? '0' + total : total;
    setTotalNum(totalItem);
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
      history.push(path);
    }, 1000);
  }
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = '/ru/';
  } else if(location.pathname.indexOf('/en') > -1){
    url = '/en/';
  } else {
    url = '/';
  }
  return (
    <>
  <section className="section section_theme_dark section-front-services">
    <div className="container section-front-services__container">
      <div className="section-front-services_abstraction abstraction">
      <svg className="abstraction-img-long">
        <use href={sprite + "#abstraction-long"}></use>
      </svg>
      </div>
      <div className="section-front-services__header section__header section__header_right">
      <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{props.servicesInfo.services_section_headline}</h2>
      <p className="section-description section-description_theme_light">{props.servicesInfo.services_section_description}</p>
      </div>
      <Swiper
        className="swiper main-services"
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        breakpoints={{
          576: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        }}
        navigation={{
          nextEl: ".main-services-next",
          prevEl: ".main-services-prev"
        }}
        pagination={{
          el: ".main-services-pagination",
          type: "progressbar"
        }}
        onSlideChange={handlerSlideChange}
        onSwiper={handlerOnSwiper}
      >
        <div className="swiper-wrapper">
        {Object.values(props.servicesItem).map((itemService, indexService) => {
          let urlService;
          if(location.pathname.indexOf('/ru') > -1){
            urlService = `/ru/services/${itemService.slug}`;
          } else if(location.pathname.indexOf('/en') > -1){
            urlService = `/en/services/${itemService.slug}`;
          } else {
            urlService = `/services/${itemService.slug}`;
          }
          return <SwiperSlide key={indexService}>
            <div className="front-service-wgt">
              <div className="front-service-wgt__inner">
                <Link className="front-service-wgt__img-link" to={urlService} onClick={event => {handlerComponents(event, urlService)}}>
                  <img className="front-service-wgt__img img-block" src={itemService.images['service-front-thumbnail']} alt="" />
                </Link>
              </div>
              <div className="front-service-wgt__content">
                <Link className="front-service-wgt__headline-link" to={urlService} onClick={event => {handlerComponents(event, urlService)}}>
                  <h3 className="front-service-wgt__headline">{itemService.title.rendered}</h3>
                </Link>
                <div className="front-service-wgt__content-inner">
                  <p className="front-service-wgt__description" dangerouslySetInnerHTML={{
                    __html: itemService.excerpt.rendered
                  }}></p>
                  <Link className="main-link" to={urlService} onClick={event => {handlerComponents(event, urlService)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}
                    <svg className="main-link__arrow">
                      <use href={sprite + "#arrow"}></use>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
                </SwiperSlide>})}
                </div>
      </Swiper>
      <div className="section-front-services__footer">
      <div className="section-front-services__footer-inner">
        <div className="slider-info">
          <span className="slider-info__active-num">{currentItem}</span>
            <div className="slider-pagination main-services-pagination"></div>
          <span className="slider-info__total-num">{totalNum}</span>
        </div>
        <div className="slider-nav">
          <div className="slider-nav__prev main-services-prev">
            <svg className="slider-nav__icon">
              <use href={sprite + "#prev"}></use>
            </svg>
          </div>
          <div className="slider-nav__next main-services-next">
            <svg className="slider-nav__icon">
              <use href={sprite + "#next"}></use>
            </svg>
          </div>
        </div>
      </div>
      <Link className="btn btn-main btn-main_theme_light section-front-services__footer-link" to={`${url}services-type`} onClick={event => {handlerComponents(event, `${url}services-type`)}}>{translateWords({ua: 'Переглянути всі послуги', ru: 'Просмотреть все услуги', en: 'View all services'}, location)}</Link>
      </div>
      </div>
    </section>
    </>
  );
}
export default HomeServices;
