import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import './ArchiveCarreersBanner.css';
function ArchiveCarreersBanner(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  return (
    <section className="section section-carreers-banner">
    <div className="container">
      <div className="section_row">
        <div className="section__col section-carreers-banner__left">
          <h1 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.postTypeInfo.career_headline}</h1>
          <h3 className="section-under-headline">{props.postTypeInfo.career_description}</h3>
          <div className="section-carreers-banner__dropdown">
            <div className="input-container">
              <input type="text" id="job" value={props.valueSearch} onChange={props.handlerSearch} required="required" />
              <label htmlFor="job">{translateWords({ua: 'Пошук відкритих вакансій', ru: 'Поиск открытых вакансий', en: 'Search for open vacancies'}, location)}</label>
              <div className="bar"></div>
            </div>
          </div>
        </div>
        <div className="section__col section-carreers-banner__right">
          <img className="img-block" src={props.postTypeInfo.career_image.sizes['ukrserviceprojecttheme-carrer-thumb']} alt="" />
        </div>
      </div>
      </div>
    </section>
  );
}
export default ArchiveCarreersBanner;
