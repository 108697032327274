import React, { useState } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import './HomeBanner.css';
function HomeBanner(props) {
  SwiperCore.use([Autoplay, Navigation, Pagination])
  const [currentItem, setCurrentItem] = useState('01');
  const [totalNum, setTotalNum] = useState(null);
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSlideChange(e){
    let current = e.realIndex + 1;
    let currentItem = (current < 10) ? '0' + current : current;
    setCurrentItem(currentItem);
  }
  function handlerOnSwiper(e){
    let total = e.activeIndex;
    let totalItem = (total < 10) ? '0' + total : total;
    setTotalNum(totalItem);
  }
  return (
    Object.values(props.itemsBanner).map((item, index) => {
      let obj = Object.assign({}, item.front_banner_slider);
    return <section className="front-banner swiper-count" key={index}>
      <div className="front-banner__overlay-init"></div>
      <Swiper
        className="main-slider"
        slidesPerView={'auto'}
        spaceBetween={5}
        loop={true}
        autoplay={{
            delay: 5000,
            disableOnInteraction: false
        }}
        navigation={{
          nextEl: ".banner-nav__next",
          prevEl: ".banner-nav__prev"
        }}
        pagination={{
          el: ".banner-pagination",
          type: "progressbar"
        }}
        onSlideChange={handlerSlideChange}
        onSwiper={handlerOnSwiper}
      >
        <div className="swiper-wrapper">
          {Object.values(obj).map((slideItem, i) => {
            return <SwiperSlide className="swiper-slide main-slider__slide" key={i}>
                    <div className="main-slider__slide-overlay"></div>
                    <img className="front-banner__img" src={slideItem.banner_slide_img.url} alt={slideItem.banner_slide_img.alt} />
                    <div className="front-banner__content">
                      {i < 1 ? <h1 className="front-banner__headline">{slideItem.banner_slide_headline}</h1> : <h2 className="front-banner__headline">{slideItem.banner_slide_headline}</h2>}
                      <p className="front-banner__description">{slideItem.banner_slide_description}</p>
                      <a className="front-banner__link slide-effect" href={slideItem.banner_slide_link.url} target={slideItem.banner_slide_link.target}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</a>
                    </div>
                  </SwiperSlide>})}
          })}
        </div>
      </Swiper>
      <div className="front-banner__footer">
        <AnchorLink className="front-banner__scroll" href="#home-about">
          <svg className="front-banner__scroll-icon">
            <use href={sprite + "#scroll"}></use>
          </svg>
          <div className="front-banner__scroll-txt">{translateWords({ua: 'Прокрутити вниз', ru: 'Прокрутить вниз', en: 'Scroll down'}, location)}</div>
        </AnchorLink>
        <div className="slider-info slider-info_right">
          <span className="slider-info__active-num">{currentItem}</span>
            <div className="slider-pagination banner-pagination"></div>
          <span className="slider-info__total-num">{totalNum}</span>
        </div>
        <div className="slider-nav">
          <div className="slider-nav__prev banner-nav__prev">
            <svg className="slider-nav__icon">
              <use href={sprite + "#prev"}></use>
            </svg>
          </div>
          <div className="slider-nav__next banner-nav__next">
            <svg className="slider-nav__icon">
              <use href={sprite + "#next"}></use>
            </svg>
          </div>
        </div>
      </div>
      {Object.values(props.contacts).map((contact, index) => {
      return <ul className="banner-social" key={index}>
        {contact.facebook_link && <li className="banner-social__item">
          <a className="banner-social__link" href={contact.facebook_link} target="_blank" rel="noreferrer">
            <svg className="banner-social__icon">
              <use href={sprite + "#facebook"}></use>
            </svg>
          </a>
        </li>}
        {contact.youtube_link && <li className="banner-social__item">
          <a className="banner-social__link" href={contact.youtube_link} target="_blank" rel="noreferrer">
            <svg className="banner-social__icon">
              <use href={sprite + "#youtube"}></use>
            </svg>
          </a>
        </li>}
        {contact.telegram_link && <li className="banner-social__item">
          <a className="banner-social__link" href={contact.telegram_link} target="_blank" rel="noreferrer">
            <svg className="banner-social__icon">
              <use href={sprite + "#telegram"}></use>
            </svg>
          </a>
        </li>}
      </ul>})}
    </section>
  })
  );
}
export default HomeBanner;
