import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import './HomeTestimonials.css';
function HomeTestimonials(props) {
  SwiperCore.use([Autoplay, Navigation, Pagination, A11y])
  const [currentItem, setCurrentItem] = useState('01');
  const [totalNum, setTotalNum] = useState(null);
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSlideChange(e){
    let current = e.snapIndex + 1;
    let currentItem = (current < 10) ? '0' + current : current;
    setCurrentItem(currentItem);
  }
  function handlerOnSwiper(e){
    let total = Math.ceil(e.activeIndex / 4);
    let totalItem = (total < 10) ? '0' + total : total;
    setTotalNum(totalItem);
  }
  return (
    <section className="section section_theme_dark section-front-testimonials">
    <div className="container">
      <div className="section-front-testimonials__abstraction abstraction">
      <svg className="abstraction-img">
        <use href={sprite + "#abstraction"}></use>
      </svg>
      </div>
      <div className="section_row">
      <div className="abstraction-circle abstraction-circle_theme_light abstraction-circle_size_sm"></div>
      <div className="section__col section-front-testimonials__left">
        <div className="section-front-testimonials__left-inner">
          <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{props.testimonialsInfo.testimonials_section_headline}</h2>
          <p className="section-description section-description_theme_light">{props.testimonialsInfo.testimonials_section_description}</p>
        </div>
        <div className="section-front-testimonials__footer">
            <div className="slider-info">
              <span className="slider-info__active-num">{currentItem}</span>
                <div className="slider-pagination main-testimonials-pagination"></div>
              <span className="slider-info__total-num">{totalNum}</span>
            </div>
            <div className="slider-nav">
              <div className="slider-nav__prev main-testimonials-prev">
                <svg className="slider-nav__icon">
                  <use href={sprite + "#prev"}></use>
                </svg>
              </div>
              <div className="slider-nav__next main-testimonials-next">
                <svg className="slider-nav__icon">
                  <use href={sprite + "#next"}></use>
                </svg>
              </div>
            </div>
        </div>
      </div>
      <div className="section__col section-front-testimonials__right">
      <Swiper
        className="swiper main-testimonials"
        slidesPerView={1}
        spaceBetween={15}
        navigation={{
          nextEl: ".main-testimonials-next-mob",
          prevEl: ".main-testimonials-prev-mob"
        }}
        pagination={{
          el: ".main-testimonials-pagination",
          type: "progressbar"
        }}
        breakpoints={{
          768: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 1,
            slidesPerColumn: 2,
            slidesPerGroup: 2,
            slidesPerColumnFill: 'row',
            navigation: {
              nextEl: ".main-testimonials-next",
              prevEl: ".main-testimonials-prev"
            }
          },
          1440: {
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerGroup: 2,
            slidesPerColumnFill: 'row',
          }
        }}
        onSlideChange={handlerSlideChange}
        onSwiper={handlerOnSwiper}
      >
           <div className="swiper-wrapper">
            {Object.values(props.testimonialsItem).map((item, index) => {
              return <SwiperSlide key={index}>
                <div className="testimonial-wgt">
                  <div className="testimonial-wgt__position">{item.position}</div>
                  <h3 className="testimonial-wgt__headline">{item.title.rendered}</h3>
                  <p className="testimonial-wgt__description" dangerouslySetInnerHTML={{
                    __html: item.excerpt.rendered
                  }}></p>
                  <div className="testimonial-wgt__footer">
                    <a className="main-link" href={item.acf.link} target="_blank" rel="noreferrer">{translateWords({ua: 'Відкрити', ru: 'Открыть', en: 'Open'}, location)}
                      <svg className="main-link__arrow">
                        <use href={sprite + "#arrow"}></use>
                      </svg>
                    </a>
                    <div className="testimonial-wgt__social-info">
                      <ul className="testimonial-wgt__social">
                      {item.acf.twitter && <li className="testimonial-wgt__social-item">
                                  <a className="testimonial-wgt__social-link" href={item.acf.twitter} target="_blank" rel="noreferrer">
                                    <svg className="testimonial-wgt__social-icon">
                                      <use href={sprite + "#twitter"}></use>
                                    </svg>
                                  </a>
                                </li>}
                                {item.acf.facebook && <li className="testimonial-wgt__social-item">
                                            <a className="testimonial-wgt__social-link" href={item.acf.facebook} target="_blank" rel="noreferrer">
                                              <svg className="testimonial-wgt__social-icon">
                                                <use href={sprite + "#facebook"}></use>
                                              </svg>
                                            </a>
                                          </li>}
                                          {item.acf.instagram && <li className="testimonial-wgt__social-item">
                                                      <a className="testimonial-wgt__social-link" href={item.acf.instagram} target="_blank" rel="noreferrer">
                                                        <svg className="testimonial-wgt__social-icon">
                                                          <use href={sprite + "#instagram"}></use>
                                                        </svg>
                                                      </a>
                                                    </li>}
                      </ul>
                      <img src={item.images['ukrserviceprojecttheme-testimonials']} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            })}
           </div>
         </Swiper>
         <div className="section-front-testimonials__footer-mob">
           <div className="slider-nav">
             <div className="slider-nav__prev main-testimonials-prev-mob">
               <svg className="slider-nav__icon">
                 <use href={sprite + "#prev"}></use>
               </svg>
             </div>
             <div className="slider-nav__next main-testimonials-next-mob">
               <svg className="slider-nav__icon">
                 <use href={sprite + "#next"}></use>
               </svg>
             </div>
           </div>
          </div>
      </div>
      </div>
      </div>
      </section>
  );
}
export default HomeTestimonials;
