import React from "react";
import './TermsContent.css';
function TermsContent(props) {
  return (
      <section className="section single-content section-terms-content">
      <div className="container" dangerouslySetInnerHTML={{
        __html: props.termsContent.rendered
      }}></div>
      </section>
);
}
export default TermsContent;
