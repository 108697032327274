import React, { useState } from "react";
import VisibilitySensor from 'react-visibility-sensor';
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './HomeAbout.css';
function HomeAbout(props) {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function onChange (isVisible) {
    if(isVisible){
      setVisible(isVisible);
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
      history.push(path);
    }, 1000);
  }
  return (
    <>
  {Object.values(props).map((item, index) => {
    let url;
    if(location.pathname.indexOf('/ru') > -1){
      url = '/ru/';
    } else if(location.pathname.indexOf('/en') > -1){
      url = '/en/';
    } else {
      url = '/';
    }
  return <section id="home-about" className="section section-front-about" key={index}>
    <VisibilitySensor onChange={onChange} partialVisibility={'top'} minTopValue={200}>
    <div className="container">
      <div className="section_row">
        <div className="section__col section-front-about__left">
          <h2 className={visible ? 'section-headline section-headline_theme_dark section-headline_size_lg visible' : 'section-headline section-headline_theme_dark section-headline_size_lg'}>{item.about_section_headline}</h2>
          <div className={visible ? 'section-front-about__content visible' : 'section-front-about__content'} dangerouslySetInnerHTML={{
            __html: item.about_section_description
          }}>
          </div>
          <div className={visible ? 'section-front-about__btn-link visible' : 'section-front-about__btn-link'}>
            <Link className="btn btn-main" to={`${url}about`} onClick={event => {handlerComponents(event, url + 'about')}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
          </div>
        </div>
        <div className="section__col section-front-about__right">
          <div className="section-front-about__right_abstraction">
            <svg className="abstraction-img">
              <use href={sprite + "#abstraction"}></use>
            </svg>
          </div>
          <div className={visible ? 'section-front-about__img-wrap' : 'section-front-about__img-wrap visible'}>
            {visible && <div className="element-animation"></div>}
            <img className="section-front-about__img" src={item.about_section_thumbnail.sizes['about-thumbnail']} alt={item.about_section_thumbnail.alt} />
          </div>
        </div>
      </div>
      </div>
      </VisibilitySensor>
    </section>
  })}
      </>
  );
}
export default HomeAbout;
