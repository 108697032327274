import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import './components/ArchiveServicesDocNav.css';
import ArchiveServicesDocBanner from './components/ArchiveServicesDocBanner';
import ArchiveServicesDocList from './components/ArchiveServicesDocList';
import Collebrate from './components/Collebrate';
function ArchiveServicesDoc() {
  const location = useLocation();
  const [serviceBannerInfo, setServiceBannerInfo] = useState([]);
  const [items, setItems] = useState([]);
  const [docCategory, setDocCategory] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Services document page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-doc-services-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setServiceBannerInfo(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
        fetch(`${language}/wp-json/wp/v2/services-doc-category`)
          .then(res => res.json())
          .then(
            (result) => {
                  setDocCategory(result);
                  fetch(`${language}/wp-json/ukr/v2/my_services_category_query/?tax_query[0][slug]=${result[0].slug}`)
                    .then(res => res.json())
                    .then(
                      (result) => {
                        setItems(result);
                      },
                      (error) => {

                      }
                    )
            },
            (error) => {

            }
          )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(serviceBannerInfo.acf && items){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  function hanlderCategoryPosts(event, slug){
    event.preventDefault();
          fetch(`${language}/wp-json/ukr/v2/my_services_category_query/?tax_query[0][slug]=${slug}`)
            .then(res => res.json())
            .then(
              (result) => {
                setItems(result);
              },
              (error) => {

              }
            )
  }
  return (
    <>
      {serviceBannerInfo.acf ? <ArchiveServicesDocBanner bannerInfo={serviceBannerInfo.acf} /> : ''}
      {docCategory ? <section className="service-nav">
      <div className="container">
  <ul className="service-nav-list">
  {Object.values(docCategory).map((item, index) => {
    let classLink = '';
    if(index === 0){
      classLink = 'service-nav-list__link active';
    } else {
      classLink = 'service-nav-list__link';
    }
    if(item.count > 0){
    return <li className="service-nav-list__item" key={index}>
      <button className={classLink} type="button" onClick={event => {hanlderCategoryPosts(event, item.slug)}}>{item.name}</button>
    </li>
  }
  })}
  </ul>
  </div>
  </section> : ''}
      {items ? <ArchiveServicesDocList items={items} /> : ''}
      <Collebrate />
    </>
  );
}
export default ArchiveServicesDoc;
