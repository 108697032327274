import React from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleServiceBanner.css';
function SingleServiceDocBanner(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  return (
      <section className="section section-service-banner section-service-banner__doc">
      <div className="container">
            <h1 className="section-headline section-headline_theme_dark section-headline_size_sm">{props.serviceTitle.rendered}</h1>
            <div className="section-service-banner__content" dangerouslySetInnerHTML={{
              __html: props.serviceExcerpt.rendered
            }}></div>
            <div className="section-service-banner__scroll">
                <a className="btn btn-main btn-main__scroll" href="#service-content">
                  <span className="btn-main__scroll-txt">{translateWords({ua: 'Читати більше', ru: 'Читать больше', en: 'Read more'}, location)}</span>
                  <svg className="btn-main__scroll-icon">
                    <use href={sprite + "#arrow-down"}></use>
                  </svg>
                </a>
        </div>
        </div>
      </section>
  );
}
export default SingleServiceDocBanner;
