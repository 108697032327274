import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import './HomeClients.css';
function HomeClients(props) {
  const location = useLocation();
  const history = useHistory();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = '/ru/contacts';
  } else if(location.pathname.indexOf('/en') > -1){
    url = '/en/contacts';
  } else {
    url = '/contacts';
  }
  return (
    <section className="section section-front-clients">
    <div className="container">
      <div className="section-front-clients__header">
      <h2 className="section-headline section-headline_size_sm">{props.clientsInfo.clients_section_headline}</h2>
      <h3 className="section-front-clients__headline">{props.clientsInfo.clients_section_unheadline}</h3>
      </div>
      <Swiper
        className="swiper main-clients"
        spaceBetween={2}
        slidesPerView={1}
        breakpoints={{
          576: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 5,
          }
        }}
      >
      <div className="swiper-wrapper">
        {Object.values(props.clientsItem).map((item, index) => {
          return   <SwiperSlide className="swiper-slide" key={index}>
                    <div className="client-wgt">
                      {item.acf.link ? <a className="client-wgt__link" href={item.acf.link} target="_blank" rel="noreferrer">
                        <img className="img-block client-wgt__logo" src={item.images['medium']} alt="" />
                      </a> : <div className="client-wgt__link"><img className="img-block client-wgt__logo" src={item.images['medium']} alt="" /></div>}
                    </div>
                  </SwiperSlide>
        })}
      </div>
      </Swiper>
      <div className="section-front-clients__content">
      <p dangerouslySetInnerHTML={{
        __html: props.clientsInfo.clients_section_description
      }}></p>
      <Link className="section-front-clients__contacts-link" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Зв\'язатись', ru: 'Связаться', en: 'Get In Touch'}, location)}</Link>
      </div>
      </div>
    </section>

  );
}
export default HomeClients;
