import React from "react";
import './TermsBanner.css';
function TermsBanner(props) {
  return (
      <section className="section section-terms-banner">
      <div className="container">
        <div className="section-terms-banner__header">
          <h1 className="section-headline section-headline_size_sm">{props.termsTitle.rendered}</h1>
        </div>
        </div>
      </section>
);
}
export default TermsBanner;
