import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './CategoryProjectList.css';
function CategoryProjectList(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
    <section className="section section-projects-category-list">
      <div className="container">
        <div className="section-projects-category-list__content">
          <div className="projects-category-info">
            <div className="projects-category-info__headline-wrap">
              <h3 className="projects-category-info__headline">{translateWords({ua: 'Виберіть категорію та натисніть', ru: 'Выберите категорию и нажмите', en: 'Select a category and press'}, location)}</h3>
            </div>
          </div>
          <div className="projects-category-list">
          {Object.values(props.categories).map((item, index) => {
            let url;
            if(location.pathname.indexOf('/ru') > -1){
              url = `/ru/category-projects/${item.slug}`;
            } else if(location.pathname.indexOf('/en') > -1){
              url = `/en/category-projects/${item.slug}`;
            } else {
              url = `/category-projects/${item.slug}`;
            }
            return <div className="projects-category-wgt" key={index}>
              <Link className="projects-category-wgt__link" to={url} onClick={event => {handlerComponents(event, url)}}></Link>
                <h2 className="projects-category-wgt__headline">{item.name}</h2>
                <div className="projects-category-wgt__icon-wrap">
                  <svg className="projects-category-wgt__icon">
                    <use href={sprite + "#arrow"}></use>
                  </svg>
                </div>
            </div>
          })}
          </div>
        </div>
      </div>
    </section>
  );
}
export default CategoryProjectList;
