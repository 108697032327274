import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './SingleNewsNav.css';
function SingleNewsNav(props) {
  const location = useLocation();
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  let urlPrev;
  let urlNext;
  if(location.pathname.indexOf('/ru') > -1){
    if(props.prev){
      urlPrev = `/ru/notes/${props.prev.slug}`;
    }
    if(props.next){
      urlNext = `/ru/notes/${props.next.slug}`;
    }
  } else if(location.pathname.indexOf('/en') > -1){
    if(props.prev){
      urlPrev = `/en/notes/${props.prev.slug}`;
    }
    if(props.next){
      urlNext = `/en/notes/${props.next.slug}`;
    }
  } else {
    if(props.prev){
      urlPrev = `/notes/${props.prev.slug}`;
    }
    if(props.next){
      urlNext = `/notes/${props.next.slug}`;
    }
  }
  return (
    <>
    {props.prev || props.next ? <section className="section section-news-nav">
    <div className="container">
    <div className="section-news-nav__inner">
      {props.prev && <div className="section-news-nav__prev">
        <Link className="btn btn-main" to={urlPrev} onClick={event => {handlerComponents(event, urlPrev)}}>{props.prev.title}</Link>
      </div>}
      {props.next && <div className="section-news-nav__next">
        <Link className="btn btn-main" to={urlNext} onClick={event => {handlerComponents(event, urlNext)}}>{props.next.title}</Link>
      </div>}
      </div>
      </div>
    </section> : ''}
    </>
);
}
export default SingleNewsNav;
