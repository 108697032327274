import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './ArchiveServicesDocList.css';
function ArchiveServicesDocList(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
<section className="section section-services-list">
<div className="container">
<div className="section_row section-services-list__row">
{Object.values(props.items).map((item, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/services-doc/${item.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/services-doc/${item.slug}`;
  } else {
    url = `/services-doc/${item.slug}`;
  }
return <div className="service-wgt-ds" key={index}>
<div className="service-wgt-ds__inner">
  <h3 className="service-wgt-ds__headline">{item.title}</h3>
  <p className="service-wgt-ds__excerpt" dangerouslySetInnerHTML={{
    __html: item.excerpt
  }}></p>
  <Link className="main-link" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}
    <svg className="main-link__arrow">
      <use href={sprite + "#arrow"}></use>
    </svg>
  </Link>
</div>
</div>
})}
</div>
</div>
</section>
);
}
export default ArchiveServicesDocList;
