import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ArchiveServicesBanner from './components/ArchiveServicesBanner';
import ArchiveServicesList from './components/ArchiveServicesList';
import Collebrate from './components/Collebrate';
function ArchiveServices() {
  const location = useLocation();
  const [serviceBannerInfo, setServiceBannerInfo] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Services page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-main-services-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setServiceBannerInfo(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/services?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setServiceItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(serviceBannerInfo.acf && serviceItems){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {serviceBannerInfo.acf ? <ArchiveServicesBanner bannerInfo={serviceBannerInfo.acf} /> : ''}
      {serviceItems ? <ArchiveServicesList serviceItems={serviceItems} /> : ''}
      <Collebrate />
    </>
  );
}
export default ArchiveServices;
