import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleProjectTestimonial.css';
function SingleProjectTestimonial(props) {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/testimonials/${props.testimonial}?per_page=1`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  })
  return (
    <section className="section section_theme_dark single-project-testimonial">
    <div className="container">
      <div className="single-project-testimonial__abstraction">
        <svg className="abstraction-img abstraction">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{translateWords({ua: 'Відгук замовника про проєкт', ru: 'Отзыв заказчика о проекте', en: 'Customer feedback on the project'}, location)}</h2>
      <div className="single-project-testimonial__content">
        {Object.keys(items).length > 0 && <div className="testimonial-wgt">
          <div className="testimonial-wgt__position">{items.acf.who_name}</div>
          <h3 className="testimonial-wgt__headline">{items.title.rendered}</h3>
          <p className="testimonial-wgt__description" dangerouslySetInnerHTML={{
            __html: items.excerpt.rendered
          }}></p>
          <div className="testimonial-wgt__footer">
            <a className="main-link" href={items.acf.link} target="_blank" rel="noreferrer">{translateWords({ua: 'Відкрити', ru: 'Открыть', en: 'Open'}, location)}
              <svg className="main-link__arrow">
                <use href={sprite + "#arrow"}></use>
              </svg>
            </a>
            <div className="testimonial-wgt__social-info">
              <ul className="testimonial-wgt__social">
                {items.acf.twitter && <li className="testimonial-wgt__social-item">
                  <a className="testimonial-wgt__social-link" href={items.acf.twitter} target="_blank" rel="noreferrer">
                    <svg className="testimonial-wgt__social-icon">
                      <use href={sprite + "#twitter"}></use>
                    </svg>
                  </a>
                </li>}
                {items.acf.facebook && <li className="testimonial-wgt__social-item">
                  <a className="testimonial-wgt__social-link" href={items.acf.facebook} target="_blank" rel="noreferrer">
                    <svg className="testimonial-wgt__social-icon">
                      <use href={sprite + "#facebook"}></use>
                    </svg>
                  </a>
                </li>}
                {items.acf.instagram && <li className="testimonial-wgt__social-item">
                  <a className="testimonial-wgt__social-link" href={items.acf.instagram} target="_blank" rel="noreferrer">
                    <svg className="testimonial-wgt__social-icon">
                      <use href={sprite + "#instagram"}></use>
                    </svg>
                  </a>
                </li>}
              </ul>
              <img src={items.images['ukrserviceprojecttheme-testimonials']} alt="" />
            </div>
          </div>
          </div>
        }
        </div>
        </div>
    </section>
  );
}
export default SingleProjectTestimonial;
