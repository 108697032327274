import React from "react";
import './SingleServiceContent.css';
function SingleServiceContent(props) {
  return (
      <section id="service-content" className="section-service-content" dangerouslySetInnerHTML={{
        __html: props.serviceContent.rendered
      }}></section>
  );
}
export default SingleServiceContent;
