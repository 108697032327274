import React from "react";
import sprite from '../media/icons.svg';
import './AboutValues.css';
function AboutValues(props) {
  let valuesInfo = props.valuesInfo;
  return (
    <section className="section section_theme_dark section-about-values">
    <div className="container">
      <div className="section-about-values__abstraction section-about-values__abstraction_top abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      <div className="section-about-values__header section__header">
        <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{valuesInfo.headline}</h2>
        <p className="section-description section-description_theme_light">{valuesInfo.unheadline}</p>
      </div>
      <div className="section_row section-about-values__row">
        <div className="section__col section-about-values__left">
          <img className="img-block" src={valuesInfo.image.sizes['ukrserviceprojecttheme-values-thumb']} alt="" />
        </div>
        <div className="section__col section-about-values__right">
          <ol className="values-list">
            {Object.values(valuesInfo.values).map((valueItem, valueKey) => {
              return <li className="values-list__item" key={valueKey}>{valueItem.value}</li>
            })}
          </ol>
        </div>
      </div>
      <div className="section-about-values__abstraction section-about-values__abstraction_bottom abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      </div>
    </section>
  );
}
export default AboutValues;
