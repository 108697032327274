import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import NotFoundContent from './components/NotFoundContent';
import Collebrate from './components/Collebrate';
function NotFound() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Page Not Found';
  }, []);
  return (
    <>
      <NotFoundContent />
      <Collebrate />
    </>
  );
}
export default NotFound;
