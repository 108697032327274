import React from "react";
import sprite from '../media/icons.svg';
import './CategoryProjectBanner.css';
function CategoryProjectBanner(props) {
  return (
    <section className="section section-projects-category-banner">
      <div className="abstraction section-projects-category-banner__abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      <div className="container">
        <h1 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.pageCategoryInfo.project_category_headline}</h1>
        <h3 className="section-under-headline">{props.pageCategoryInfo.project_category_description}</h3>
      </div>
    </section>
  );
}
export default CategoryProjectBanner;
