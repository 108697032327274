import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AboutBanner from './components/AboutBanner';
import AboutHistory from './components/AboutHistory';
import AboutValues from './components/AboutValues';
import AboutStatistics from './components/AboutStatistics';
import AboutCareers from './components/AboutCareers';
import Collebrate from './components/Collebrate';
function About() {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [aboutInfo, setAboutInfo] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [carrer, setCarrer] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | About page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/acf/v3/options/acf-main-page-theme`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/pages?slug=about`)
      .then(res => res.json())
      .then(
        (result) => {
          setAboutInfo(result);
        },
        (error) => {

        }
      )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/acf/v3/options/acf-statistics-theme`)
      .then(res => res.json())
      .then(
        (result) => {
          setStatistics(result);
        },
        (error) => {

        }
      )
  }, [language]);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-career-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setCarrer(result);
            },
            (error) => {

            }
          )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(items.acf && aboutInfo[0] && statistics.acf && carrer.acf){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
      <>
        {aboutInfo[0] ? <AboutBanner bannerTitle={aboutInfo[0].title} bannerUntitle={aboutInfo[0].acf.untitle} bannerContent={aboutInfo[0].content} bannerImg={aboutInfo[0].images['about-thumb']} /> : ''}
        {aboutInfo[0] && aboutInfo[0].acf.company_history ? <AboutHistory historyInfo={aboutInfo[0].acf.company_history} /> : ''}
        {aboutInfo[0] && aboutInfo[0].acf.our_values ? <AboutValues valuesInfo={aboutInfo[0].acf.our_values} /> : ''}
        {statistics.acf && items.acf ? <AboutStatistics staticticsItem={statistics.acf} statisticsInfo={items.acf.statistics_section} /> : ''}
        {carrer.acf ? <AboutCareers careerInfo={carrer.acf} /> : ''}
        <Collebrate />
      </>
  );
}
export default About;
