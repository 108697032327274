import React from "react";
import './SingleNewsBanner.css';
function SingleNewsBanner(props) {
  return (
<section className="single-news-banner">
<div className="overlay"></div>
<img className="single-news-banner__img" src={props.newsThumbnail} alt="" />
<div className="single-news-banner__content">
<div className="container">
<div className="single-news-banner__inner">
<h1 className="single-news-banner__headline">{props.newsTitle.rendered}</h1>
<div className="single-news-banner__description" dangerouslySetInnerHTML={{
  __html: props.newsExcerpt.rendered
}}></div>
</div>
</div>
</div>
</section>
);
}
export default SingleNewsBanner;
