import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation
} from "react-router-dom";
import SingleNewsBanner from './components/SingleNewsBanner';
import SingleNewsContent from './components/SingleNewsContent';
import SingleNewsNav from './components/SingleNewsNav';
import SingleNewsInteresting from './components/SingleNewsInteresting';
import Collebrate from './components/Collebrate';
function SingleNews() {
  const location = useLocation();
  let { slug } = useParams();
  const [items, setItems] = useState([]);
  const [interestingItems, setInterestingItems] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | News single page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/notes?slug=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [language, slug])
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/notes?per_page=3`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInterestingItems(data);
      });
  }, [language])
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(items[0]){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {items[0] ? <>
      <SingleNewsBanner newsTitle={items[0].title} newsExcerpt={items[0].excerpt} newsThumbnail={items[0].images.full} />
      <SingleNewsContent newsContent={items[0].content} />
      <SingleNewsNav prev={items[0].previous} next={items[0].next} />
      </> : ''}
      {interestingItems ? <SingleNewsInteresting interestingItems={interestingItems} /> : ''}
      <Collebrate />
    </>
  );
}
export default SingleNews;
