import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './ArchiveNewsBanner.css';
function ArchiveNewsBanner(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/notes/${props.bannerTop.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/notes/${props.bannerTop.slug}`;
  } else {
    url = `/notes/${props.bannerTop.slug}`;
  }
  return (
    <section className="section section-news-banner">
    <div className="container">
      <div className="section_row">
        <div className="section__col section-news-banner__left">
          <h2 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.bannerInfo.archive_news_headline}</h2>
          <h3 className="section-under-headline">{props.bannerInfo.archive_news_description}</h3>
        </div>
        <div className="section__col section-news-banner__right">
          <div className="top-news-wgt">
            <div className="overlay"></div>
            <span className="top-news-wgt__label">{translateWords({ua: 'Топ новина', ru: 'Топ новость', en: 'Top news'}, location)}</span>
            <div className="top-news-wgt__content">
              <Link to={url} onClick={event => {handlerComponents(event, url)}}>
                <h3 className="top-news-wgt__headline">{props.bannerTop.title}</h3>
              </Link>
              <Link className="btn btn-main btn-main_theme_light" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
            </div>
            <img className="img-block" src={props.bannerTop.thumbnail} alt="" />
          </div>
          <div className="abstraction section-news-banner__abstraction">
            <svg className="abstraction-img">
              <use href={sprite + "#abstraction"}></use>
            </svg>
          </div>
        </div>
      </div>
      </div>
      </section>
  );
}
export default ArchiveNewsBanner;
