import React from "react";
import sprite from '../media/icons.svg';
import './AboutHistory.css';
function AboutHistory(props) {
  let historyInfo = props.historyInfo;
  return (
    <section className="section section-about-history">
    <div className="container">
      <div className="section_row section-about-history__row">
      <div className="section__col section-about-history__left">
      <div className="section-about-history__abstraction abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      <img className="section-about-history__img img-block" src={historyInfo.history_image.sizes['ukrserviceprojecttheme-about-thumb']} alt="" />
      </div>
      <div className="section__col section-about-history__right">
      <h2 className="section-headline section-headline_size_sm">{historyInfo.history_headline}</h2>
      <div dangerouslySetInnerHTML={{
        __html: historyInfo.history_content
      }}>
      </div>
      </div>
      </div>
      </div>
    </section>
);
}
export default AboutHistory;
