import React from "react";
import './SingleNewsContent.css';
function SingleNewsContent(props) {
  return (
<section className="section single-content" dangerouslySetInnerHTML={{
  __html: props.newsContent.rendered
}}>
</section>
);
}
export default SingleNewsContent;
