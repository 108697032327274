import React, { useState, useEffect } from "react";
import sprite from '../media/icons.svg';
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './Header.css';
function Header() {
  const location = useLocation();
  const [stickyHeader, setStickyHeader] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [menuActiveClass, setMenuActiveClass] = useState(false);
  const [closeMenuAnimation, setCloseMenuAnimation] = useState(false);
  const [menuContent, setMenuContent] = useState(false);
  const [items, setItems] = useState([]);
  const [infoMenu, setInfoMenu] = useState([]);
  const [menu, setMenu] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [language] = useState(getLanguage(location));
  const [content, setContent] = useState(false);
  // setTimeout(() => {
  //   if(!content){
  //     setContent(true);
  //   }
  // }, 3000);
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerMenu(){
    setCloseMenuAnimation(false);
    setMenuActive(!menuActive);
    setTimeout(() => {
      setMenuActiveClass(!menuActiveClass);
      setMenuContent(!menuContent);
    }, 1700)
  }
  function handlerCloseMenu(){
    setCloseMenuAnimation(true);
    setMenuContent(!menuContent);
    setTimeout(() => {
      setMenuActive(!menuActive);
      setMenuActiveClass(!menuActiveClass);
    }, 1700)
  }

  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
    if(menuActive){
      setCloseMenuAnimation(true);
      setMenuContent(!menuContent);
      setTimeout(() => {
        setMenuActive(!menuActive);
        setMenuActiveClass(!menuActiveClass);
      }, 1700)
    }
  }
const [dropdown, setDropdown] = useState(false);
function dropdownHandler(){
  setDropdown(!dropdown);
}

const isSticky = (e) => {
    const scrollTop = window.scrollY;
     scrollTop >= 250 ? setStickyHeader(true) : setStickyHeader(false);
 };
useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
});
useEffect(() => {
  fetch(`${language}/wp-json/acf/v3/options/acf-options-theme`)
    .then(res => res.json())
    .then(
      (result) => {
        setItems(result);
      },
      (error) => {

      }
    )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/acf/v3/options/acf-contacts-theme`)
      .then(res => res.json())
      .then(
        (result) => {
          setContacts(result);
        },
        (error) => {

        }
      )
    }, [language]);
    useEffect(() => {
      fetch(`${language}/wp-json/wp/v3/main_menu`)
        .then(res => res.json())
        .then(
          (result) => {
            setMenu(result);
          },
          (error) => {

          }
        )
      }, [language]);
      useEffect(() => {
        fetch(`${language}/wp-json/wp/v3/info_menu`)
          .then(res => res.json())
          .then(
            (result) => {
              setInfoMenu(result);
            },
            (error) => {

            }
          )
}, [language]);
let path = location.pathname.substr(location.pathname.lastIndexOf('/')+1);
let pathSlug;
if(location.pathname.indexOf('/ru') > -1 || location.pathname.indexOf('/en') > -1){
  pathSlug = location.pathname.substr(location.pathname.indexOf('/')+3)
} else {
  pathSlug = location.pathname;
}
let languageList;
if(location.pathname.indexOf('/en') > -1){
  languageList = <><li className="header-language__item">
    <a className="header-language__link" href={pathSlug}>Укр</a>
  </li></>;
} else {
  languageList = <><li className="header-language__item">
    <a className="header-language__link" href={`/en${pathSlug}`}>Eng</a>
  </li></>;
}
let loaderItems;
if(location.pathname.indexOf('/ru') > -1) {
  loaderItems = <div className="preloader-box">
    <div>У</div>
    <div>К</div>
    <div>Р</div>
    <div>С</div>
    <div>Е</div>
    <div>Р</div>
    <div>В</div>
    <div>И</div>
    <div>С</div>
  </div>
} else if(location.pathname.indexOf('/en') > -1) {
  loaderItems = <div className="preloader-box">
    <div>U</div>
    <div>K</div>
    <div>R</div>
    <div>S</div>
    <div>E</div>
    <div>R</div>
    <div>V</div>
    <div>I</div>
    <div>S</div>
  </div>
} else {
  loaderItems = <div className="preloader-box">
    <div>У</div>
    <div>К</div>
    <div>Р</div>
    <div>С</div>
    <div>Е</div>
    <div>Р</div>
    <div>В</div>
    <div>І</div>
    <div>С</div>
  </div>
}
  return (
    <>
    {menuActive && <div className={menuActiveClass ? 'menu menu_active' : 'menu'}>
      <div className={closeMenuAnimation ? 'menu-blind menu-blind_active' : 'menu-blind'}>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
        <div className="menu-blind__item"></div>
      </div>
      <div className={menuContent ? 'menu-content menu-content_active' : 'menu-content'}>
      <div className="container">
        <div className="menu-content__header">
          {Object.values(items).map((item, index) => {
            return <img key={index} src={item.logo_light} alt="" />
          })}
          <button className="menu-content__btn-close" type="button" onClick={handlerCloseMenu}>
            <svg className="menu-btn__icon-close">
              <use href={sprite + "#menu-close"}></use>
            </svg>
          </button>
          </div>
        </div>
        <div className="container">
        <div className="menu-content__wgts">
          <div className="menu-wgt">
            <div className="menu-wgt__headline">{translateWords({ua: 'Навігація по сайту', ru: 'Навигация по сайту', en: 'Site navigation'}, location)}</div>
            <ul className="menu-items">
            {Object.values(menu).map((menuItem, index) => {
              let url;
              if(location.pathname.indexOf('/ru') > -1){
                url = `/ru${menuItem.url}`;
              } else if(location.pathname.indexOf('/en') > -1){
                url = `/en${menuItem.url}`;
              } else {
                url = menuItem.url;
              }
              return <li className="menu-items__item" key={index}>
                <Link className="menu-items__link" to={url} onClick={event => {handlerComponents(event, url)}}>{menuItem.title}</Link>
              </li>
            })}
            </ul>
          </div>
          <div className="menu-wgt">
          <div className="menu-wgt__headline">{translateWords({ua: 'Інформація', ru: 'Информация', en: 'Information'}, location)}</div>
          <ul className="menu-items">
          {Object.values(infoMenu).map((menuItem, index) => {
            let url;
            if(location.pathname.indexOf('/ru') > -1){
              url = `/ru${menuItem.url}`;
            } else if(location.pathname.indexOf('/en') > -1){
              url = `/en${menuItem.url}`;
            } else {
              url = menuItem.url;
            }
            return <li className="menu-items__item" key={index}>
              <Link className="menu-items__link" to={url} onClick={event => {handlerComponents(event, url)}}>{menuItem.title}</Link>
            </li>
          })}
          </ul>
          </div>
          {Object.values(contacts).map((contact, index) => {
            let objPhones = Object.assign({}, contact.phones);
            let objEmails = Object.assign({}, contact.emails);
          return <div className="menu-wgt">
            <div className="menu-contacts-wgt">
              <div className="menu-contacts-wgt__headline">{translateWords({ua: 'Адреса', ru: 'Адрес', en: 'Address'}, location)}</div>
              <div className="menu-contacts-wgt__address">{contact.address}</div>
            </div>
            <div className="menu-contacts-wgt">
              <div className="menu-contacts-wgt__headline">{translateWords({ua: 'Телефони', ru: 'Телефоны', en: 'Phones'}, location)}</div>
              <ul className="menu-contacts-phones">
                {Object.values(objPhones).map((item, i) => {
                return <li className="menu-contacts-phones__item" key={i}>
                  <a className="menu-contacts-phones__link" href={"tel:" + item.phone}>{item.phone}</a>
                </li>
                })}
              </ul>
            </div>
            <div className="menu-contacts-wgt">
              <div className="menu-contacts-wgt__headline">{translateWords({ua: 'Електроні пошти', ru: 'Электронные почты', en: 'Emails'}, location)}</div>
              <ul className="menu-contacts-emails">
                {Object.values(objEmails).map((item, i) => {
                return <li className="menu-contacts-emails__item" key={i}>
                  <a className="menu-contacts-emails__link" href={"mailto:" + item.emails}>{item.emails}</a>
                </li>
                })}
              </ul>
            </div>
            <ul className="social-info-header">
              <li className="social-info-header__item">
                <a className="social-info-header__link" href={contact.facebook_link} target="_blank" rel="noreferrer">
                  <svg className="social-info-header__icon">
                    <use href={sprite + "#facebook"}></use>
                  </svg>
                </a>
              </li>
              <li className="social-info-header__item">
                <a className="social-info-header__link" href={contact.youtube_link} target="_blank" rel="noreferrer">
                  <svg className="social-info-header__icon">
                    <use href={sprite + "#youtube"}></use>
                  </svg>
                </a>
              </li>
              <li className="social-info-header__item">
                <a className="social-info-header__link" href={contact.telegram_link} target="_blank" rel="noreferrer">
                  <svg className="social-info-header__icon">
                    <use href={sprite + "#telegram"}></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          })}
        </div>
        </div>
      </div>
    </div>}
    <header className={location.pathname === '/' || location.pathname === '/ru' || location.pathname === '/en' || location.pathname === '/ru/' || location.pathname === '/en/' || location.pathname === `/notes/${path}` || location.pathname === `/ru/notes/${path}` || location.pathname === `/en/notes/${path}` || location.pathname === `/projects/${path}` || location.pathname === `/ru/projects/${path}` || location.pathname === `/en/projects/${path}` ? stickyHeader ? 'header header__sticky' : 'header' : stickyHeader ? 'header header_theme_dark header__sticky' : 'header header_theme_dark'}>
      <div className="container">
        <div className="container__row header__row">
          <div className="header__logo">
          {Object.values(items).map((item, index) => {
            let url;
            if(location.pathname.indexOf('/ru') > -1){
              url = '/ru/';
            } else if(location.pathname.indexOf('/en') > -1){
              url = '/en/';
            } else {
              url = '/';
            }
            return <Link to={url} onClick={event => {handlerComponents(event, url)}} key={index}>
               <img src={location.pathname === '/' || location.pathname === '/ru' || location.pathname === '/en' || location.pathname === '/ru/' || location.pathname === '/en/' || location.pathname === `/notes/${path}` || location.pathname === `/ru/notes/${path}` || location.pathname === `/en/notes/${path}` || location.pathname === `/projects/${path}` || location.pathname === `/projects/${path}` || location.pathname === `/ru/projects/${path}` || location.pathname === `/en/projects/${path}` || stickyHeader ? item.logo_light : item.logo_dark} alt="" />
            </Link>
          })}
          </div>
          <div className="header__menu">
            <ul className="header-menu">
            {Object.values(menu).map((menuItem, index) => {
              let url;
              if(location.pathname.indexOf('/ru') > -1){
                url = `/ru${menuItem.url}`;
              } else if(location.pathname.indexOf('/en') > -1){
                url = `/en${menuItem.url}`;
              } else {
                url = menuItem.url;
              }
              return <li className="header-menu__item" key={index}>
                <Link className={location.pathname === url || location.pathname + '/' === url ? 'header-menu__link active' : 'header-menu__link'} to={url} onClick={event => {handlerComponents(event, url)}}>{menuItem.title}</Link>
              </li>
            })}
            </ul>
            <div className={dropdown ? 'dropdown active' : 'dropdown'}>
              <div className="dropdown__btn" onClick={dropdownHandler}>
                <div className="dropdown__current">{translateWords({ua: 'Укр', en: 'Eng'}, location)}</div>
                <svg className="dropdown__icon">
                  <use href={sprite + "#dropdown-icon"}></use>
                </svg>
              </div>
              <div className="dropdown__content">
                <ul className="header-language">{languageList}</ul>
              </div>
            </div>
            <button className="menu-btn" type="button" onClick={handlerMenu}>
              <svg className="menu-btn__icon">
                <use href={sprite + "#menu-icon"}></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
    </>
  );
}
export default Header;
