import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './ArchiveProjectsList.css';
function ArchiveProjectsList(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
    <section className="section section-projects-list">
    <div className="container">
    {Object.values(props.projectItems).map((item, index) => {
      let classWgt = '';
      if(index % 2 === 0){
        classWgt = 'projects-wgt projects-wgt__standart';
      } else {
        classWgt = 'projects-wgt projects-wgt__reverse';
      }
      let url;
      if(location.pathname.indexOf('/ru') > -1){
        url = `/ru/projects/${item.slug}`;
      } else if(location.pathname.indexOf('/en') > -1){
        url = `/en/projects/${item.slug}`;
      } else {
        url = `/projects/${item.slug}`;
      }
      return <div id={`item-${index}`} className={classWgt} key={index}>
              <div className="projects-wgt__imgs">
                <img className="img-block projects-wgt__img1" src={props.slug ? item.thumbnail : item.images['projects-thumb-lg']} alt="" />
                {props.slug ? item.thumbnail_sm && <img className="projects-wgt__img2" src={item.thumbnail_sm.sizes['ukrserviceprojecttheme-project-sm']} alt="" /> : item.acf.thumbnail_sm && <img className="projects-wgt__img2" src={item.acf.thumbnail_sm.sizes['ukrserviceprojecttheme-project-sm']} alt="" />}
              </div>
              <div className="projects-wgt__info">
                <Link className="projects-wgt__link" to={url} onClick={event => {handlerComponents(event, url)}}>
                  <h2 className="projects-wgt__headline">{props.slug ? item.title : item.title.rendered}</h2>
                </Link>
                <p className="projects-wgt__excerpt" dangerouslySetInnerHTML={{
                  __html: props.slug ? item.excerpt : item.excerpt.rendered
                }}></p>
                <Link className="projects-wgt__permalink btn btn-main btn-main__scroll" to={url} onClick={event => {handlerComponents(event, url)}}>
                  <span className="btn-main__scroll-txt">{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</span>
                  <svg className="btn-main__scroll-icon">
                    <use href={sprite + "#arrow-down"}></use>
                  </svg>
                </Link>
              </div>
            </div>
    })}
      </div>
    </section>
);
}
export default ArchiveProjectsList;
