import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import Collapsible from 'react-collapsible';
import './FaqList.css';
function FaqList() {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [faq, setFaq] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/faq-category`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);

          Object.values(result).map((item, index) => {
          return fetch(`${language}/wp-json/ukr/v2/my_faq_category_query/?tax_query[0][slug]=${item.slug}`)
            .then(res => res.json())
            .then(
              (result) => {
                  setFaq(faq => [...faq, result]);
              },
              (error) => {

              }
            )
          }, [language])
        },
        (error) => {

        }
      )
  }, [language]);
  return (
    <section className="section section-faq-list">
      <div className="container">
      {Object.values(items).map((item, index) => {
        return <div className="section-faq-list__faq-item" key={index}>
          <h2 className="section-faq-list__headline">{item.name}</h2>
          {faq[index] && Object.values(faq[index]).map((faqItem, i) => {
              return <Collapsible className="faq-wgt" trigger={faqItem.title} key={i}>
                <div className="faq-wgt__arrow">
                  <svg className="submit-button__arrow">
                    <use href={sprite + "#arrow-down"}></use>
                  </svg>
                </div>
                <p dangerouslySetInnerHTML={{
                  __html: faqItem.content
                }}></p>
              </Collapsible>
            })}
        </div>
      })}
      </div>
    </section>
  );
}
export default FaqList;
