import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sprite from './media/icons.svg';
import ArchiveNewsBanner from './components/ArchiveNewsBanner';
import ArchiveNewsList from './components/ArchiveNewsList';
import Collebrate from './components/Collebrate';
function ArchiveNews() {
  const location = useLocation();
  const [infoBanner, setInfoBanner] = useState([]);
  const [itemsBanner, setBannerItems] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const [language] = useState(getLanguage(location));
  const [currentPage, setCurrentPage] = useState(2);
  const [loadNews, setLoadNews] = useState(false);
  const [emptyResult, setEmptyResult] = useState(false);
  const [resultLength, setResultLength] = useState(0);
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | News page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-notes-list-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setInfoBanner(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/ukr/v2/my_meta_query/?meta_query[0][key]=top_news&meta_query[0][value]=1&meta_query[0][compare]=LIKE`)
      .then(res => res.json())
      .then(
        (result) => {
          setBannerItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/notes?per_page=3`)
      .then(res => res.json())
      .then(
        (result) => {
          setNewsItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(itemsBanner && infoBanner.acf && newsItems){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  function handlerLoadNotes(event){
    setLoadNews(true);
    fetch(`${language}/wp-json/wp/v2/notes?per_page=${currentPage * 3}`)
      .then(res => res.json())
      .then(
        (result) => {
          setTimeout(function() {
            setNewsItems(result);
            setCurrentPage(currentPage + 1);
            setResultLength(result.length);
            setLoadNews(false);
            if(result.length === resultLength){
              setEmptyResult(true);
            }
          }, 1000);
        },
        (error) => {

        }
      )
  }
  return (
    <>
      {itemsBanner && infoBanner.acf ? <ArchiveNewsBanner bannerInfo={infoBanner.acf} bannerTop={itemsBanner} /> : ''}
      {newsItems ? <ArchiveNewsList newsItems={newsItems} /> : ''}
      {!emptyResult ?<div className="load-more load-more__notes">
        <button className="main-link main-link__btn" onClick={event => {handlerLoadNotes(event)}}>
          {loadNews ? <div className="loader-posts"><div id="loader"></div><div className="loader-section section-left"></div><div className="loader-section section-right"></div></div> : <>
          {translateWords({ua: 'Більше записів', ru: 'Больше записей', en: 'Load more'}, location)}
          <svg className="main-link__arrow">
            <use href={sprite + "#chevron"}></use>
          </svg></>}
        </button>
      </div> : <div className="empty-result">{translateWords({ua: 'Записи скінчились', ru: 'Записи закончились', en: 'Recordings ended'}, location)}</div>}
      <Collebrate />
    </>
  );
}
export default ArchiveNews;
