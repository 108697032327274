import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './HomeContacts.css';
function HomeContacts(props) {
  const location = useLocation();
  const [valueName, setValueName] = useState('');
  const [valueContact, setValueContact] = useState('');
  const [valueMessage, setValueMessage] = useState('');
  const [works, setWorks] = useState([]);
  const [infoWorking, setInfoWorking] = useState('');
  const [language] = useState(getLanguage(location));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSubmitContact(event){
    event.preventDefault();
    fetch("https://ukrservisproekt.com.ua/wp-content/themes/urkserviceprojecttheme/form-submit-contacts.php",
      {   method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode : 'same-origin',
          credentials: 'same-origin' ,
          body: JSON.stringify({name: valueName, contact: valueContact, message: valueMessage})
      })
      .then(function(response) {
        return response.text()
      }).then(function(text) {
        let result = JSON.parse(text);
        if(result === 'success'){
          setSuccess(true);
          setLoading(false);
        } else {
          setErrors(result);
          setLoading(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      });
  }
  function handlerValueName(event){
    setValueName(event.target.value);
  }
  function handlerValueContact(event){
    setValueContact(event.target.value);
  }
  function handlerValueMessage(event){
    setValueMessage(event.target.value);
  }
  useEffect(() => {
  fetch(`${language}/wp-json/acf/v3/options/acf-options-theme`)
    .then(res => res.json())
    .then(
      (result) => {
        let nowDate = new Date();
        if(nowDate.getDay() === 0 || nowDate.getDay() === 6){
          setInfoWorking(translateWords({ua: 'Зачинено: Відкриється у понеділок', ru: 'Закрито: Откроется в понедельник', en: 'Closed: Opens on Monday'}, location));
        } else {
          if(result.acf.working_hours){
            let str = result.acf.working_hours[nowDate.getDay() - 1].value;
            let lastChar = "-";
            let textBefore = str.slice(str.indexOf(lastChar), str.length).replace('- ', '');
            let textBeforeHour = textBefore.slice(0, textBefore.lastIndexOf(":"));
            if(textBeforeHour > nowDate.getHours()){
              setInfoWorking(translateWords({ua: 'Відкрито: Закриється в ' + textBefore, ru: 'Открыто: Закроется в ' + textBefore, en: 'Opened: Will close in' + textBefore}, location));
            } else {
              setInfoWorking(translateWords({ua: 'Відкриється завтра', ru: 'Откроеться завтра', en: 'Will open tomorrow'}, location));
            }
          }
        }
        setWorks(result);
      },
      (error) => {

      }
    )
  }, [language]);
  return (
    <section className="section section-front-contacts">
    <div className="container">
<div className="section-front-contacts__abstraction section-front-contacts__abstraction_left abstraction">
<svg className="abstraction-img">
  <use href={sprite + "#abstraction"}></use>
</svg>
</div>
<div className="section-front-contacts__abstraction section-front-contacts__abstraction_right abstraction">
<svg className="abstraction-img-long">
  <use href={sprite + "#abstraction-long"}></use>
</svg>
</div>
<div className="section-front-contacts__header">
<h2 className="section-headline section-headline_size_sm">{props.contactsHeadline}</h2>
</div>
<div className="section_row">
<div className="section__col contacts-wgt">
  <h3 className="contacts-wgt__headline">{translateWords({ua: 'Наші контакти', ru: 'Наши контакты', en: 'Our contacts'}, location)}</h3>
  <ul className="contacts-list">
    {props.contacts.emails && <li className="contacts-list__item">
      <svg className="contacts-list__icon">
        <use href={sprite + "#mail"}></use>
      </svg>
      <div>
      {Object.values(props.contacts.emails).map((email, index) => {
        let obj = Object.assign({}, email);
        return Object.values(obj).map((item, i) => {
          return <a className="contacts-list__link" href="https://google.com" key={i}>{item}</a>
        })
      })}
      </div>
    </li>}
    {props.contacts.phones && <li className="contacts-list__item">
      <svg className="contacts-list__icon">
        <use href={sprite + "#phone"}></use>
      </svg>
      <div>
      {Object.values(props.contacts.phones).map((phone, index) => {
        let obj = Object.assign({}, phone);
        return Object.values(obj).map((item, i) => {
          return <a className="contacts-list__link" href="https://google.com" key={i}>{item}</a>
      })
    })}
    </div>
    </li>}
    <li className="contacts-list__item">
      <svg className="contacts-list__icon">
        <use href={sprite + "#map"}></use>
      </svg>{props.contacts.address}
    </li>
  </ul>
  <h3 className="contacts-wgt__headline">{translateWords({ua: 'Соціальні мережі', ru: 'Социальные сети', en: 'Social networks'}, location)}</h3>
  <ul className="contacts-list contacts-list__social">
    <li className="contacts-list__item">
      <svg className="contacts-list__icon">
        <use href={sprite + "#facebook2"}></use>
      </svg>
      <a className="contacts-list__link" href={props.contacts.facebook_link} target="_blank" rel="noreferrer">facebook</a>
    </li>
    <li className="contacts-list__item">
      <svg className="contacts-list__icon">
        <use href={sprite + "#youtube2"}></use>
      </svg>
      <a className="contacts-list__link" href={props.contacts.youtube_link} target="_blank" rel="noreferrer">youtube</a>
    </li>
  </ul>
</div>
<div className="section__col contacts-wgt">
{success ?
<><div className="checkmark draw">
  <div className="checkmark__content">
    <div className="checkmark__headline">{translateWords({ua: 'Дякуємо за повідомлення!', ru: 'Спасибо за сообщение!', en: 'Thank you for message!'}, location)}</div>
    <div className="checkmark__message">{translateWords({ua: 'Наш менеджер зв\'яжеться з Вами найближчим часом.', ru: 'Наш менеджер свяжется с Вами в ближайшее время.', en: 'Our manager will contact you shortly.'}, location)}</div>
  </div>
  </div></> : <div className="contacts-wgt__form-wrapper">
    <h3 className="contacts-wgt__headline contacts-wgt__headline_theme_light">{translateWords({ua: 'Напишіть нам', ru: 'Напишите нам', en: 'Write to us'}, location)}</h3>
    <form className="form" onSubmit={handlerSubmitContact}>
      <div className="input-container">
        <input type="text" id="name" value={valueName} onChange={handlerValueName} disabled={loading ? 'disabled' : ''} />
        <label htmlFor="name">{translateWords({ua: 'Ваше ім\'я', ru: 'Ваше имя', en: 'Your name'}, location)}</label>
        <div className="bar"></div>
      </div>
      <div className="input-container">
        <input type="text" id="contact" value={valueContact} onChange={handlerValueContact} disabled={loading ? 'disabled' : ''} />
        <label htmlFor="contact">{translateWords({ua: 'Пошта або телефон', ru: 'Почта или телефон', en: 'Mail or phone'}, location)}</label>
        <div className="bar"></div>
      </div>
      <div className="input-container input-container_size_lg">
        <input type="text" id="message" value={valueMessage} onChange={handlerValueMessage} disabled={loading ? 'disabled' : ''} />
        <label htmlFor="message">{translateWords({ua: 'Текст повідомлення', ru: 'Текст сообщения', en: 'Message text'}, location)}</label>
        <div className="bar"></div>
      </div>
      {loading ? <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
         <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg> : ''}
      {errors && <div className="errors">
        <div className="errors__content">
        {Object.values(errors).map((item, index) => {
          return <p className="error" key={index}>{item}</p>;
        })}
        </div>
      </div>}
      <button className={loading ? 'submit-button submit-button__loading' : 'submit-button'} type="submit" name="submit" disabled={loading ? 'disabled' : ''}>
        <span className="submit-button__txt">{translateWords({ua: 'Зв\'язатись', ru: 'Связаться', en: 'Get in touch'}, location)}</span>
        <span className="submit-button__arrow-wrapper">
          <svg className="submit-button__arrow">
            <use href={sprite + "#arrow"}></use>
          </svg>
        </span>
      </button>
    </form>
  </div>}
</div>
  {works.acf && <div className="section__col contacts-wgt">
  {!success && <h3 className="contacts-wgt__headline">{translateWords({ua: 'Часи праці', ru: 'Время работы', en: 'Working hours'}, location)}</h3>}
  <div className="contacts-wgt__status">
    <svg className="contacts-wgt__clock-icon">
      <use href={sprite + "#clock"}></use>
    </svg>
    <div className="contacts-wgt__status-info">{infoWorking}</div>
  </div>
  <div className="hours-work">
  {Object.values(works.acf.working_hours).map((item, index) => {
    return <div className="hours-work__wgt" key={index}>
        <div className="hours-work__key">{item.day}</div>
        <div className="hours-work__value">{item.value}</div>
      </div>
  })}
  </div>
</div>}
</div>
</div>
</section>
  );
}
export default HomeContacts;
