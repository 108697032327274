import React from "react";
import './FaqBanner.css';
function FaqBanner(props) {
  return (
    <section className="section section-faq-banner">
    <div className="container">
      <div className="section-faq-banner__header">
        <h1 className="section-headline section-headline_size_sm">{props.faqBannerInfo.faq_headline}</h1>
        <h3 className="section-under-headline">{props.faqBannerInfo.faq_description}</h3>
      </div>
      </div>
    </section>
  );
}
export default FaqBanner;
