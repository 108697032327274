import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleCarreersContact.css';
function SingleCarreersContact(props) {
  const location = useLocation();
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const carrerMail = props.carrerContacts.carrer_mail;
  const carrerPhone = props.carrerContacts.carrer_phone;
  function handlerSubmitResume(event){
    event.preventDefault();
    const data = new FormData();
    data.append('data', resume);
    fetch("https://ukrservisproekt.com.ua/wp-content/themes/urkserviceprojecttheme/form-submit-resume.php", {
      method: 'POST',
      body: data
      })
      .then(response => response.json())
      .then(data => {
        if(data === 'success'){
          setSuccess(true);
          setLoading(false);
        } else {
          setErrors(data);
          setLoading(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      })
      .catch(error => {

    })
  }
  function handlerFile(event){
    setResume(event.target.files[0]);
  }
  return (
    <section className="section section_theme_dark single-carreers-contact">
    <div className="container">
      <div className="section_row">
        <div className="section__col single-carreers-contact__left">
          <h2 className="single-carreers-contact__headline">{translateWords({ua: 'Контактна інформація про вакансії', ru: 'Контактная информация о вакансиях', en: 'Contacts about vacancy information'}, location)}</h2>
          <ul className="carreers-contact">
            {carrerMail ? <li className="carreers-contact__item">
              <a className="carreers-contact__link" href={`mailto:${carrerMail}`}>{carrerMail}</a>
              <svg className="carreers-contact__icon">
                <use href={sprite + "#mail"}></use>
              </svg>
            </li> : ''}
            {carrerPhone ? <li className="carreers-contact__item">
              <a className="carreers-contact__link" href={`tel:+${carrerPhone.replace(/[^0-9]/g,"")}`}>{carrerPhone}</a>
              <svg className="carreers-contact__icon">
                <use href={sprite + "#phone"}></use>
              </svg>
            </li> : ''}
          </ul>
        </div>
        <div className={success ? "section__col single-carreers-contact__right single-carreers-contact__right_success" : "section__col single-carreers-contact__right"}>
          {success ? <div className="checkmark-resume">
            <div className="checkmark-resume__content">
              <h2 className="checkmark-resume__headline">{translateWords({ua: 'Ваше резюме успішно відправленно!', ru: 'Ваше резюме успешно отправлено!', en: 'Your resume has been successfully submitted!'}, location)}</h2>
            </div>
            </div> :
          <><h2 className="single-carreers-contact__headline">{translateWords({ua: 'Надсилайте своє резюме', ru: 'Отправьте свое резюме', en: 'Submit your resume'}, location)}</h2>
          {loading ? <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
             <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg> : <form className="carreers-resume-form" onSubmit={handlerSubmitResume}>
            <div className="file-upload">
              <label>
                <input type="file" name="userfile" id="userfile" className="w100" accept="image/*" onChange={handlerFile} />
                <svg className="file-upload__icon">
                  <use href={sprite + "#upload"}></use>
                </svg>
                <span>{translateWords({ua: 'Завантажити резюме', ru: 'Загрузить резюме', en: 'Upload resume'}, location)}</span>
              </label>
            </div>
            {errors && <div className="errors">
              <div className="errors__content">
              {Object.values(errors).map((item, index) => {
                return <p className="error" key={index}>{item}</p>;
              })}
              </div>
            </div>}
            <div className="section-collebrate__btn">
              <button className="submit-button" type="submit">
                <span className="submit-button__txt">{translateWords({ua: 'Відправити резюме', ru: 'Отправить резюме', en: 'Send resume'}, location)}</span>
                <span className="submit-button__arrow-wrapper">
                  <svg className="submit-button__arrow">
                    <use href={sprite + "#arrow"}></use>
                  </svg>
                </span>
              </button>
            </div>
          </form>}</>}
        </div>
      </div>
      <div className="single-carreers-contact__abstraction abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      </div>
    </section>
  );
}
export default SingleCarreersContact;
