import React from "react";
import sprite from '../media/icons.svg';
import './ArchiveServicesDocBanner.css';
function ArchiveServicesDocBanner(props) {
  return (
    <section className="section section-archive-services-doc-banner">
    <div className="container">
    <div className="section-archive-services-banner__inner">
    <h1 className="section-headline section-headline_theme_dark section-headline_size_lg">{props.bannerInfo.doc_services_headline}</h1>
    <h3 className="section-under-headline">{props.bannerInfo.doc_services_description}</h3>
    </div>
<div className="section-services__abstraction abstraction">
  <svg className="abstraction-img">
    <use href={sprite + "#abstraction"}></use>
  </svg>
</div>
</div>
</section>
);
}
export default ArchiveServicesDocBanner;
