import React from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleCarreersContent.css';
function SingleCarreersContent(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  return (
    <section className="section single-content">
    <div className="container">
    <div dangerouslySetInnerHTML={{
      __html: props.careerContent.rendered
    }}></div>
      <div className="carreers-content-footer">
        <a className="btn btn-main btn-main__download" href={props.resumeExample} target="_blank" donwload={props.resumeExample} rel="noreferrer">
          <span className="btn-main__download-txt">{translateWords({ua: 'Завантажити приклад резюме', ru: 'Загрузить пример резюме', en: 'Download sample resume'}, location)}</span>
          <svg className="btn-main__download-icon">
            <use href={sprite + "#arrow-down"}></use>
          </svg>
        </a>
      </div>
      </div>
    </section>
  );
}
export default SingleCarreersContent;
