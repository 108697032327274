import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CategoryProjectBanner from './components/CategoryProjectBanner';
import CategoryProjectList from './components/CategoryProjectList';
import Collebrate from './components/Collebrate';
function CategoryProjects() {
  const location = useLocation();
  const [pageCategoryInfo, setPageCategoryInfo] = useState([]);
  const [categoriesProject, setCategoriesProject] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Category projects page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-project-category-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setPageCategoryInfo(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/projects-category`)
      .then(res => res.json())
      .then(
        (result) => {
          setCategoriesProject(result);
        },
        (error) => {

        }
      )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(pageCategoryInfo.acf && categoriesProject){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {pageCategoryInfo.acf ? <CategoryProjectBanner pageCategoryInfo={pageCategoryInfo.acf} /> : ''}
      {categoriesProject ? <CategoryProjectList categories={categoriesProject} /> : ''}
      <Collebrate />
    </>
  );
}
export default CategoryProjects;
