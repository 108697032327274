import React, { useState } from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import sprite from '../media/icons.svg';
import './SingleServiceCases.css';
function SingleServiceCases(props) {
  SwiperCore.use([Autoplay, Navigation, Pagination])
  const [currentItem, setCurrentItem] = useState('01');
  const [totalNum, setTotalNum] = useState(null);
  const location = useLocation();
  const history = useHistory();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function handlerSlideChange(e){
    let current = e.realIndex + 1;
    let currentItem = (current < 10) ? '0' + current : current;
    setCurrentItem(currentItem);
  }
  function handlerOnSwiper(e){
    let total = 5;
    let totalItem = (total < 10) ? '0' + total : total;
    setTotalNum(totalItem);
  }
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
    <section className="single-service-cases">
      <div className="single-service-cases__headline">
      <div className="container">
        <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{translateWords({ua: 'Проєкти послуги', ru: 'Проекты услуги', en: 'Service projects'}, location)}</h2>
        <p className="section-description section-description_theme_light">{translateWords({ua: 'Тут Ви можете ознайомитись с проєктами данної послуги', ru: 'Здесь Вы можете ознакомиться с проектами данной услуги', en: 'Here you can get acquainted with the projects of this service'}, location)}</p>
        </div>
      </div>
      <Swiper
        className="swiper cases-slider"
        spaceBetween={30}
        slidesPerView={'auto'}
        navigation={{
          nextEl: ".cases-service-next",
          prevEl: ".cases-service-prev"
        }}
        pagination={{
          el: ".cases-service-pagination",
          type: "progressbar"
        }}
        onSlideChange={handlerSlideChange}
        onSwiper={handlerOnSwiper}
      >
      <div className="swiper-wrapper">
      {Object.values(props.cases).map((item, index) => {
        let url;
        if(location.pathname.indexOf('/ru') > -1){
          url = `/ru/projects/${item.slug}`;
        } else if(location.pathname.indexOf('/en') > -1){
          url = `/en/projects/${item.slug}`;
        } else {
          url = `/projects/${item.slug}`;
        }
        return <SwiperSlide className="swiper-slide cases-slider__slide" key={index}>
          <div className="cases-slider-wgt">
            <div className="cases-slider-wgt__content">
              <Link className="cases-slider-wgt__link" to={url} onClick={event => {handlerComponents(event, url)}}>
                <h3 className="cases-slider-wgt__headline">{item.title}</h3>
              </Link>
              <Link className="btn btn-main btn-main_theme_light" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
            </div>
            <img className="img-block" src={item.thumbnail} alt="" />
          </div>
        </SwiperSlide>
      })}
      </div>
      </Swiper>
      <div className="single-service-cases__footer">
      <div className="container">
      <div className="single-service-cases__footer-inner">
        <div className="slider-info">
          <span className="slider-info__active-num">{currentItem}</span>
            <div className="slider-pagination cases-service-pagination"></div>
          <span className="slider-info__total-num">{totalNum}</span>
        </div>
        <div className="slider-nav">
          <div className="slider-nav__prev cases-service-prev">
            <svg className="slider-nav__icon">
              <use href={sprite + "#prev"}></use>
            </svg>
          </div>
          <div className="slider-nav__next cases-service-next">
            <svg className="slider-nav__icon">
              <use href={sprite + "#next"}></use>
            </svg>
          </div>
          </div>
        </div>
        </div>
      </div>
    </section>
);
}
export default SingleServiceCases;
