import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation
} from "react-router-dom";
import SingleCarreersBanner from './components/SingleCarreersBanner';
import SingleCarreersContent from './components/SingleCarreersContent';
import SingleCarreersContact from './components/SingleCarreersContact';
import Collebrate from './components/Collebrate';
function SingleCarreers() {
  const location = useLocation();
  let { slug } = useParams();
  const [items, setItems] = useState([]);
  const [themeSetting, setThemeSetting] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Carreer page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/career?slug=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [language, slug])
  useEffect(() => {
    fetch(`${language}/wp-json/acf/v3/options/acf-options-theme`)
      .then(res => res.json())
      .then(
        (result) => {
          setThemeSetting(result);
        },
        (error) => {

        }
      )
  }, [language])
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(items[0]){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
    {items[0] && themeSetting.acf ? <>
      <SingleCarreersBanner careerTitle={items[0].title} careerExcerpt={items[0].excerpt} resumeExample={themeSetting.acf.resume_example} />
      <SingleCarreersContent careerContent={items[0].content} resumeExample={themeSetting.acf.resume_example} />
    </>  : ''}
    {themeSetting.acf ? <SingleCarreersContact carrerContacts={themeSetting.acf} /> : ''}
      <Collebrate />
    </>
  );
}
export default SingleCarreers;
