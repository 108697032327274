import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ArchiveCarreersBanner from './components/ArchiveCarreersBanner';
import ArchiveCarreersList from './components/ArchiveCarreersList';
import Collebrate from './components/Collebrate';
function ArchiveCarreers() {
  const location = useLocation();
  const [postTypeInfo, setPostTypeInfo] = useState([]);
  const [carreerItems, setCarreerItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [language] = useState(getLanguage(location));
  let searchTimeout = null;
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Carreers page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-career-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setPostTypeInfo(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/career?per_page=10`)
      .then(res => res.json())
      .then(
        (result) => {
          setCarreerItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  function handlerSearch(event){
    setSearchValue(event.target.value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      fetch(`${language}/wp-json/wp/v2/career?per_page=10&search=${event.target.value}`)
        .then(res => res.json())
        .then(
          (result) => {
            setCarreerItems(result);
          },
          (error) => {

          }
        )
    }, 500);
  }
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(postTypeInfo.acf && carreerItems){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {postTypeInfo.acf ? <ArchiveCarreersBanner postTypeInfo={postTypeInfo.acf} valueSearch={searchValue} handlerSearch={handlerSearch} /> : ''}
      {carreerItems ? <ArchiveCarreersList carreerItems={carreerItems} /> : ''}
      <Collebrate />
    </>
  );
}
export default ArchiveCarreers;
