import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './ArchiveCarreersList.css';
function ArchiveCarreersList(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  return (
    <section className="section-carreers-list">
      <div className="section-carreers-list__vacancies">
      <div className="container">
        <div className="section-carreers-list__vacancies-headline">{translateWords({ua: 'Знайдено', ru: 'Найдено', en: 'Found'}, location)}</div>
        <div className="section-carreers-list__vacancies-value">{props.carreerItems.length + ' ' + translateWords({ua: 'відкритих вакансій', ru: 'открытых вакансий', en: 'open vacancies'}, location)}</div>
        </div>
      </div>
      <div className="carreers-list">
      {Object.values(props.carreerItems).map((item, index) => {
        let url;
        if(location.pathname.indexOf('/ru') > -1){
          url = `/ru/career/${item.slug}`;
        } else if(location.pathname.indexOf('/en') > -1){
          url = `/en/career/${item.slug}`;
        } else {
          url = `/career/${item.slug}`;
        }
        return <div className="carreers-list__wgt" key={index}>
        <div className="container">
        <div className="carreers-list__wgt-inner">
          <div className="carreers-list__wgt-name">{item.title.rendered}</div>
          <div className="carreers-list__wgt-value" dangerouslySetInnerHTML={{
            __html: item.excerpt.rendered
          }}></div>
          <div className="carreers-list__wgt-permalink">
            <Link className="main-link" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}
              <svg className="main-link__arrow">
                <use href={sprite + "#arrow"}></use>
              </svg>
            </Link>
            </div>
          </div>
          </div>
        </div>
      })}
      </div>
    </section>
  );
}
export default ArchiveCarreersList;
