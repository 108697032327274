import React from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleProjectBanner.css';
function SingleProjectBanner(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  console.log(props);
  return (
    <section className="single-project-banner">
      <div className="overlay"></div>
      <img className="single-project-banner__img" src={props.projectThumbnail.full} alt="" />
      <div className="single-project-banner__content">
      <div className="container">
        <div className="single-project-banner__content_top">
          <div className="single-project-banner__label">{translateWords({ua: 'Проєкт', ru: 'Проект', en: 'Project'}, location)}</div>
          <h1 className="single-project-banner__headline">{props.projectTitle.rendered}</h1>
          <a className="btn btn-main btn-main_theme_light btn-main__scroll" href="#projects-content">
            <span className="btn-main__scroll-txt">{translateWords({ua: 'Прокрутити вниз', ru: 'Прокрутить вниз', en: 'Scroll down'}, location)}</span>
            <svg className="btn-main__scroll-icon">
              <use href={sprite + "#arrow-down"}></use>
            </svg>
          </a>
          </div>
        </div>
        <div className="single-project-banner__content_bottom">
        <div className="container">
        {props.projectInfo ? <div className="single-project-banner__content_bottom-inner">
        {props.projectInfo.year && <div className="single-project-banner__wgt-info">
          <div className="single-project-banner__wgt-headline">{translateWords({ua: 'Рік', ru: 'Год', en: 'Year'}, location)}</div>
          <div>{props.projectInfo.year}</div>
        </div>}
        {props.projectInfo.type && <div className="single-project-banner__wgt-info">
          <div className="single-project-banner__wgt-headline">{translateWords({ua: 'Вид', ru: 'Вид', en: 'Type'}, location)}</div>
          <div>{props.projectInfo.type}</div>
        </div>}
        {props.projectInfo.location && <div className="single-project-banner__wgt-info">
          <div className="single-project-banner__wgt-headline">{translateWords({ua: 'Локація', ru: 'Локация', en: 'Location'}, location)}</div>
          <div>{props.projectInfo.location}</div>
        </div>}
        </div> : ''}
        </div>
        </div>
      </div>
    </section>
  );
}
export default SingleProjectBanner;
