import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import './AboutCareers.css';
function AboutCareers(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/career`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/career`;
  } else {
    url = `/career`;
  }
  return (
    <section className="section section_theme_dark section-about-carreers">
    <div className="container">
      <div className="section_row">
        <div className="section__col section-about-carreers__info">
          <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{props.careerInfo.career_headline}</h2>
          <p className="section-description section-description_theme_light">{props.careerInfo.career_description}</p>
          <Link className="btn btn-main btn-main_theme_light" to={url} onClick={event => {handlerComponents(event, url)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}</Link>
        </div>
        <div className="section__col section-about-carreers__photo">
          <img className="img-block" src={props.careerInfo.career_image.sizes['ukrserviceprojecttheme-carrer-thumb']} alt="" />
        </div>
      </div>
      </div>
    </section>
  );
}
export default AboutCareers;
