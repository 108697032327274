import Header from './components/Header';
import Footer from './components/Footer';
import Home from './Home';
import About from './About';
import ArchiveNews from './ArchiveNews';
import SingleNews from './SingleNews';
import ServicesType from './ServicesType';
import ArchiveServices from './ArchiveServices';
import ArchiveServicesDoc from './ArchiveServicesDoc';
import SingleService from './SingleService';
import SingleServiceDoc from './SingleServiceDoc';
import CategoryProjects from './CategoryProjects';
import ArchiveCategoryProjects from './ArchiveCategoryProjects';
import ArchiveProjects from './ArchiveProjects';
import SingleProject from './SingleProject';
import ArchiveCarreers from './ArchiveCarreers';
import SingleCarreers from './SingleCarreers';
import Faq from './Faq';
import Contacts from './Contacts';
import Terms from './Terms';
import NotFound from './NotFound';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
function App() {
  return (
    <Router>
      <main>
        <Header />
        <Switch>
          <Route path="/terms" component={Terms} />
          <Route path="/ru/terms" component={Terms} />
          <Route path="/en/terms" component={Terms} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/ru/contacts" component={Contacts} />
          <Route path="/en/contacts" component={Contacts} />
          <Route path="/faq" component={Faq} />
          <Route path="/ru/faq" component={Faq} />
          <Route path="/en/faq" component={Faq} />
          <Route path="/career/:slug" component={SingleCarreers} />
          <Route path="/ru/career/:slug" component={SingleCarreers} />
          <Route path="/en/career/:slug" component={SingleCarreers} />
          <Route path="/career" component={ArchiveCarreers} />
          <Route path="/ru/career" component={ArchiveCarreers} />
          <Route path="/en/career" component={ArchiveCarreers} />
          <Route path="/category-projects/:slug" component={ArchiveCategoryProjects} />
          <Route path="/ru/category-projects/:slug" component={ArchiveCategoryProjects} />
          <Route path="/en/category-projects/:slug" component={ArchiveCategoryProjects} />
          <Route path="/category-projects" component={CategoryProjects} />
          <Route path="/ru/category-projects" component={CategoryProjects} />
          <Route path="/en/category-projects" component={CategoryProjects} />
          <Route path="/projects/:slug" component={SingleProject} />
          <Route path="/ru/projects/:slug" component={SingleProject} />
          <Route path="/en/projects/:slug" component={SingleProject} />
          <Route path="/projects" component={ArchiveProjects} />
          <Route path="/ru/projects" component={ArchiveProjects} />
          <Route path="/en/projects" component={ArchiveProjects} />
          <Route path="/services-doc/:slug" component={SingleServiceDoc} />
          <Route path="/ru/services-doc/:slug" component={SingleServiceDoc} />
          <Route path="/en/services-doc/:slug" component={SingleServiceDoc} />
          <Route path="/services-doc" component={ArchiveServicesDoc} />
          <Route path="/ru/services-doc" component={ArchiveServicesDoc} />
          <Route path="/en/services-doc" component={ArchiveServicesDoc} />
          <Route path="/services/:slug" component={SingleService} />
          <Route path="/ru/services/:slug" component={SingleService} />
          <Route path="/en/services/:slug" component={SingleService} />
          <Route path="/services" component={ArchiveServices} />
          <Route path="/ru/services" component={ArchiveServices} />
          <Route path="/en/services" component={ArchiveServices} />
          <Route path="/services-type" component={ServicesType} />
          <Route path="/ru/services-type" component={ServicesType} />
          <Route path="/en/services-type" component={ServicesType} />
          <Route path="/notes/:slug" component={SingleNews} />
          <Route path="/ru/notes/:slug" component={SingleNews} />
          <Route path="/en/notes/:slug" component={SingleNews} />
          <Route path="/notes" component={ArchiveNews} />
          <Route path="/ru/notes" component={ArchiveNews} />
          <Route path="/en/notes" component={ArchiveNews} />
          <Route path="/about" component={About} />
          <Route path="/ru/about" component={About} />
          <Route path="/en/about" component={About} />
          <Route path="/" component={Home} />
          <Route path="/ru" component={Home} />
          <Route path="/en" component={Home} />
          <Route path="/404" component={NotFound} />
          <Route path="/ru/404" component={NotFound} />
          <Route path="/en/404" component={NotFound} />
        </Switch>
        <Footer />
      </main>
    </Router>
  );
}

export default App;
