import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ContactsBanner from './components/ContactsBanner';
import ContactsInfo from './components/ContactsInfo';
import Collebrate from './components/Collebrate';
function Contacts() {
  const location = useLocation();
  const [contactsPageInfo, setContactsPageInfo] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [works, setWorks] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Contacts page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/pages?slug=contacts`)
      .then(res => res.json())
      .then(
        (result) => {
          setContactsPageInfo(result);
        },
        (error) => {

        }
      )
  }, [language]);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-contacts-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setContacts(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
  fetch(`${language}/wp-json/acf/v3/options/acf-options-theme`)
    .then(res => res.json())
    .then(
      (result) => {
        setWorks(result);
      },
      (error) => {

      }
    )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(contactsPageInfo[0] && contacts.acf && works.acf){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {contactsPageInfo[0] ? <ContactsBanner contactsPageInfo={contactsPageInfo[0]} /> : ''}
      <ContactsInfo contacts={contacts.acf} works={works.acf} />
      <Collebrate />
    </>
  );
}
export default Contacts;
