import React, { useState } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import sprite from '../media/icons.svg';
import './HomeStatistics.css';
function HomeStatistics(props) {
  const [visible, setVisible] = useState(false);
  function onChange (isVisible) {
    if(isVisible){
      setVisible(isVisible);
    }
  }
  return (
  <section className="section section_theme_dark section-front-statistics">
  <VisibilitySensor onChange={onChange} partialVisibility={'top'} minTopValue={200}>
  <div className="container">
    <div className="abstraction-circle abstraction-circle_theme_light abstraction-circle_size_sm"></div>
      <div className="section-front-statistics__abstraction section-front-statistics__abstraction_left abstraction">
      <svg className="abstraction-img">
        <use href={sprite + "#abstraction"}></use>
      </svg>
      </div>
      <div className="section-front-statistics__header section__header section__header_right">
      <h2 className="section-headline section-headline_theme_light section-headline_size_sm">{props.statisticsInfo.statistics_section_headline}</h2>
      <p className="section-description section-description_theme_light">{props.statisticsInfo.statistics_section_description}</p>
      </div>
      <div className="statistics-content">
        <div className="statistics-content__wgts">
        {Object.values(props.staticticsItem).map((item, index) => {
          let obj = Object.assign({}, item);
          return Object.values(obj).map((statisticItem, i) => {
            return <div className="statistic-wgt" key={i}>
              <div className="statistic-wgt__inner">
                <h3 className="statistic-wgt__headline">{statisticItem.name}</h3>
                <h4 className="statistic-wgt__description">{statisticItem.description}</h4>
              </div>
              {visible ? <CountUp start={0} end={statisticItem.value} duration={3} className="statistic-wgt__value"></CountUp> : ''}
            </div>
          })
        })}
        </div>
      </div>
      <div className="section-front-statistics__abstraction section-front-statistics__abstraction_right abstraction">
      <svg className="abstraction-img">
        <use href={sprite + "#abstraction"}></use>
      </svg>
      </div>
      </div>
      </VisibilitySensor>
    </section>
  );
}
export default HomeStatistics;
