import React, { useState } from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import sprite from '../media/icons.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import './HomeProjects.css';
function HomeProjects(props) {
  SwiperCore.use([Autoplay, Navigation, Pagination])
  const [height] = useState(['250px', '400px', '270px']);
  const [height2] = useState(['370px', '470px']);
  const location = useLocation();
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
      history.push(path);
    }, 1000);
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = '/ru/';
  } else if(location.pathname.indexOf('/en') > -1){
    url = '/en/';
  } else {
    url = '/';
  }
  return (
    <>
      <section className="section section-front-projects">
        <div className="section-front-projects__abstraction section-front-projects__abstraction_top abstraction">
          <svg className="abstraction-img">
            <use href={sprite + "#abstraction"}></use>
          </svg>
        </div>
        <div className="container">
        <div className="section_row">
          <div className="section__col section-front-projects__left">
            <h2 className="section-headline section-headline_size_sm">{props.projectsInfo.projects_section_headline}</h2>
            <p className="section-description">{props.projectsInfo.projects_section_description}</p>
            <Link className="btn btn-main section-front-projects__get-archive-btn" to={`${url}category-projects`} onClick={event => {handlerComponents(event, `${url}category-projects`)}}>{translateWords({ua: 'Переглянути всі проєкти', ru: 'Просмотреть все проекты', en: 'View all projects'}, location)}</Link>
            <div className="section-front-projects__abstraction section-front-projects__abstraction_bottom abstraction">
              <svg className="abstraction-img">
                <use href={sprite + "#abstraction"}></use>
              </svg>
            </div>
          </div>
          <div className="section__col section-front-projects__right">
            <div className="front-projects">
              <div className="front-projects__left">
                {props.projectsItem.slice(0, 3).map((item, index) => {
                  let urlProject;
                  if(location.pathname.indexOf('/ru') > -1){
                    urlProject = `/ru/projects/${item.slug}`;
                  } else if(location.pathname.indexOf('/en') > -1){
                    urlProject = `/en/projects/${item.slug}`;
                  } else {
                    urlProject = `/projects/${item.slug}`;
                  }
                  return <div className="front-project-wgt" key={index}>
                          <div className="front-project-wgt__img" style={{backgroundImage: 'url(' + item.images.full + ')', height: height[index]}}>
                            <div className="overlay"></div>
                            <div className="front-project-wgt__content">
                              <Link to={urlProject} onClick={event => {handlerComponents(event, urlProject)}}>
                                <h3 className="front-project-wgt__headline">{item.title.rendered}</h3>
                              </Link>
                              <div className="front-project-wgt__content-inner">
                                <p className="front-project-wgt__excerpt" dangerouslySetInnerHTML={{
                                  __html: item.excerpt.rendered
                                }}></p>
                                <div className="front-project-wgt__btn-more">
                                  <Link className="main-link" to={urlProject} onClick={event => {handlerComponents(event, urlProject)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}
                                    <svg className="main-link__arrow">
                                      <use href={sprite + "#arrow"}></use>
                                    </svg>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="overlay"></div>
                          </div>
                        </div>
                      })}
              </div>
              <div className="front-projects__right">
                {props.projectsItem.slice(3, 5).map((item, index) => {
                  let urlProject;
                  if(location.pathname.indexOf('/ru') > -1){
                    urlProject = `/ru/projects/${item.slug}`;
                  } else if(location.pathname.indexOf('/en') > -1){
                    urlProject = `/en/projects/${item.slug}`;
                  } else {
                    urlProject = `/projects/${item.slug}`;
                  }
                  return <div className="front-project-wgt" key={index}>
                          <div className="front-project-wgt__img" style={{backgroundImage: 'url(' + item.images.full + ')', height: height2[index]}}>
                            <div className="overlay"></div>
                            <div className="front-project-wgt__content">
                              <Link to={urlProject} onClick={event => {handlerComponents(event, urlProject)}}>
                                <h3 className="front-project-wgt__headline">{item.title.rendered}</h3>
                              </Link>
                              <div className="front-project-wgt__content-inner">
                                <p className="front-project-wgt__excerpt" dangerouslySetInnerHTML={{
                                  __html: item.excerpt.rendered
                                }}></p>
                                <div className="front-project-wgt__btn-more">
                                  <Link className="main-link" to={urlProject} onClick={event => {handlerComponents(event, urlProject)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}
                                    <svg className="main-link__arrow">
                                      <use href={sprite + "#arrow"}></use>
                                    </svg>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="overlay"></div>
                          </div>
                        </div>
                      })}
              </div>
            </div>
          </div>
        </div>
        <Swiper
          className="front-projects-slider"
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
          breakpoints={{
            576: {
              slidesPerView: 2
            }
          }}
          navigation={{
            nextEl: ".main-projects-next",
            prevEl: ".main-projects-prev"
          }}
        >
          <div className="swiper-wrapper">
            {props.projectsItem.map((item, index) => {
              let urlProject;
              if(location.pathname.indexOf('/ru') > -1){
                urlProject = `/ru/projects/${item.slug}`;
              } else if(location.pathname.indexOf('/en') > -1){
                urlProject = `/en/projects/${item.slug}`;
              } else {
                urlProject = `/projects/${item.slug}`;
              }
                return <SwiperSlide className="swiper-slide" key={index}>
                <div className="front-project-wgt">
                  <img className="img-block" src={item.images['projects-thumb-slider']} alt="" />
                  <div className="front-project-wgt__content">
                    <Link to={urlProject} onClick={event => {handlerComponents(event, urlProject)}}>
                      <h3 className="front-project-wgt__headline">{item.title.rendered}</h3>
                    </Link>
                    <div className="front-project-wgt__content-inner">
                      <p className="front-project-wgt__excerpt" dangerouslySetInnerHTML={{
                        __html: item.excerpt.rendered
                      }}></p>
                      <div className="front-project-wgt__btn-more">
                        <Link className="main-link" to={urlProject} onClick={event => {handlerComponents(event, urlProject)}}>{translateWords({ua: 'Детальніше', ru: 'Подробнее', en: 'Read more'}, location)}
                          <svg className="main-link__arrow">
                            <use href={sprite + "#arrow"}></use>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                </SwiperSlide>})}
          </div>
        </Swiper>
        <div className="section-front-projects__footer">
          <Link className="btn btn-main section-front-projects__footer-link" to={`${url}category-projects`} onClick={event => {handlerComponents(event, `${url}category-projects`)}}>{translateWords({ua: 'Переглянути всі проєкти', ru: 'Просмотреть все проекты', en: 'View all projects'}, location)}</Link>
          <div className="section-front-projects__footer-inner">
            <div className="slider-nav">
              <div className="slider-nav__prev main-projects-prev">
                <svg className="slider-nav__icon slider-nav__icon_theme_main">
                  <use href={sprite + "#prev"}></use>
                </svg>
              </div>
              <div className="slider-nav__next main-projects-next">
                <svg className="slider-nav__icon slider-nav__icon_theme_main">
                  <use href={sprite + "#next"}></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      </>
  );
}
export default HomeProjects;
