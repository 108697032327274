import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation
} from "react-router-dom";
import SingleProjectBanner from './components/SingleProjectBanner';
import SingleProjectContent from './components/SingleProjectContent';
import SingleProjectTestimonial from './components/SingleProjectTestimonial';
import SingleProjectSimilar from './components/SingleProjectSimilar';
import Collebrate from './components/Collebrate';
function SingleProject() {
  const location = useLocation();
  let { slug } = useParams();
  const [items, setItems] = useState([]);
  const [itemsSimilar, setSimilarItems] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Project single page';
  }, []);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/projects?slug=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [language, slug])
  useEffect(() => {
    fetch(`${language}/wp-json/ukr/v2/my_similar_projects_query/?project[slug]=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSimilarItems(data);
      });
  }, [language, slug])
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(items[0]){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
    {items[0] ? <>
      <SingleProjectBanner projectTitle={items[0].title} projectThumbnail={items[0].images} projectInfo={{year: items[0].acf.year, type: items[0].acf.type, location: items[0].acf.location}} />
      <SingleProjectContent items={items[0]} />
    </> : ''}
      {items[0] && items[0].acf.project_testimonial ? <SingleProjectTestimonial testimonial={items[0].acf.project_testimonial} /> : ''}
      {itemsSimilar ? <SingleProjectSimilar similarItems={itemsSimilar} /> : ''}
      <Collebrate />
    </>
  );
}
export default SingleProject;
