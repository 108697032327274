import React from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './SingleCarreersBanner.css';
function SingleCarreersBanner(props) {
  const location = useLocation();
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  return (
    <section className="section section_theme_dark single-carreers-banner">
    <div className="container">
      <div className="single-carreers-banner__header section__header">
        <h1 className="section-headline section-headline_theme_light section-headline_size_sm">{props.careerTitle.rendered}</h1>
        <p className="section-description section-description_theme_light" dangerouslySetInnerHTML={{
          __html: props.careerExcerpt.rendered
        }}></p>
        <a className="btn btn-main btn-main_theme_light btn-main__download" href={props.resumeExample} target="_blank" donwload={props.resumeExample} rel="noreferrer">
          <span className="btn-main__download-txt">{translateWords({ua: 'Завантажити приклад резюме', ru: 'Загрузить пример резюме', en: 'Download sample resume'}, location)}</span>
          <svg className="btn-main__download-icon">
            <use href={sprite + "#arrow-down"}></use>
          </svg>
        </a>
      </div>
      <div className="single-carreers-banner__abstraction abstraction">
        <svg className="abstraction-img">
          <use href={sprite + "#abstraction"}></use>
        </svg>
      </div>
      </div>
    </section>
  );
}
export default SingleCarreersBanner;
