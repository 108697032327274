import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sprite from './media/icons.svg';
import ArchiveProjectsBanner from './components/ArchiveProjectsBanner';
import ArchiveProjectsList from './components/ArchiveProjectsList';
import Collebrate from './components/Collebrate';
function ArchiveProjects() {
  const location = useLocation();
  const [projectsPageInfo, setProjectsPageInfo] = useState([]);
  const [projectItems, setProjectItems] = useState([]);
  const [language] = useState(getLanguage(location));
  const [currentPage, setCurrentPage] = useState(2);
  const [loadProjects, setLoadProjects] = useState(false);
  const [emptyResult, setEmptyResult] = useState(false);
  const [resultLength, setResultLength] = useState(0);
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | Projects page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-project-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setProjectsPageInfo(result);
            },
            (error) => {

            }
          )
  }, [language]);
  useEffect(() => {
    fetch(`${language}/wp-json/wp/v2/projects?per_page=10`)
      .then(res => res.json())
      .then(
        (result) => {
          setProjectItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(projectsPageInfo.acf && projectItems){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  function handlerLoadProjects(event){
    setLoadProjects(true);
    fetch(`${language}/wp-json/wp/v2/projects?per_page=${currentPage * 10}`)
      .then(res => res.json())
      .then(
        (result) => {
          setTimeout(function() {
            setProjectItems(result);
            setCurrentPage(currentPage + 1);
            setResultLength(result.length);
            setLoadProjects(false);
            if(result.length === resultLength){
              setEmptyResult(true);
            }
          }, 1000);
        },
        (error) => {

        }
      )
  }
  return (
    <>
      {projectsPageInfo.acf ? <ArchiveProjectsBanner projectsPageInfo={projectsPageInfo.acf} /> : ''}
      {projectItems ? <ArchiveProjectsList projectItems={projectItems} /> : ''}
      {!emptyResult ?<div className="load-more load-more__projects">
        <button className="main-link main-link__btn" onClick={event => {handlerLoadProjects(event)}}>
          {loadProjects ? <div className="loader-posts"><div id="loader"></div><div className="loader-section section-left"></div><div className="loader-section section-right"></div></div> : <>
          {translateWords({ua: 'Більше проєктів', ru: 'Больше проектов', en: 'Load more'}, location)}
          <svg className="main-link__arrow">
            <use href={sprite + "#chevron"}></use>
          </svg></>}
        </button>
      </div> : <div className="empty-result">{translateWords({ua: 'Проєкти скінчились', ru: 'Проекти закончились', en: 'Projects ended'}, location)}</div>}
      <Collebrate />
    </>
  );
}
export default ArchiveProjects;
