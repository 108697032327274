import React from "react";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
function ContentNav(props) {
  const location = useLocation();
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
return (
<ul className="content-nav">
{Object.values(props.items).map((item, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/services/${item.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/services/${item.slug}`;
  } else {
    url = `/services/${item.slug}`;
  }
return <li className="content-nav__item" key={index}>
  <Link className="content-nav__link active" to={url} onClick={event => {handlerComponents(event, url)}}>{item.title.rendered}</Link>
</li>
})}
</ul>
);
}
export default ContentNav;
