import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FaqBanner from './components/FaqBanner';
import FaqList from './components/FaqList';
import Collebrate from './components/Collebrate';
function Faq() {
  const location = useLocation();
  const [faqBannerInfo, setFaqBannerInfo] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = 'Ukrservice project | FAQ page';
  }, []);
  useEffect(() => {
        fetch(`${language}/wp-json/acf/v3/options/acf-faq-theme`)
          .then(res => res.json())
          .then(
            (result) => {
              setFaqBannerInfo(result);
            },
            (error) => {

            }
          )
  }, [language]);
  function handlerLoadComponent(){
    let loadingComponentsEl = document.querySelector('.loading-components');
    let loaderEl = document.querySelector('.loader');
    if(faqBannerInfo.acf){
      if(loaderEl){
        loaderEl.remove();
      }
      if(loadingComponentsEl){
        loadingComponentsEl.classList.add('loading-components_load');
        setTimeout(function() {
          loadingComponentsEl.remove();
        }, 1000);
      }
    }
  }
  handlerLoadComponent();
  return (
    <>
      {faqBannerInfo.acf ? <FaqBanner faqBannerInfo={faqBannerInfo.acf} /> : ''}
      <FaqList />
      <Collebrate />
    </>
  );
}
export default Faq;
